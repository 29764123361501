// To
export const todoPurposeString = `## Purpose and description

The To do page contains a list of detected events that require System or Port Owner attention.

Each ToDo will typically include a link to one or more resources, such as a Shore Power Session or a Vessel (see example below). Certain ToDo triggers a status change to "Inspection Required" on associated Port Calls, preventing them from being invoiced until the ToDo is resolved.

Detected ToDos appear under the “ToDo” tab and be marked as “Done” using the checkboxes to the left. Multiple ToDos can be marked simultaneously.

Users can include a custom comment to any ToDo by clicking on the comment icon. The comment will be displayed directly in the table row and may contain up to 300 characters. We recommend including who wrote the comment, such as with a quick “-Ola N”.

ToDos marked ‘Done’ can be deleted or moved back to the “ToDo” tab using the checkboxes

<aside>
❗ **NOTE:** Comments and status changes (marking a ToDo as done) will appear for everyone with access to the To Dos.

</aside>

## ToDo Types

The following table includes the current overview of the different ToDo Types the system detects

Vessel with multiple ongoing sessions 

IMO not found in port

Vessel duplicate

Vessel name does not match Marine Traffic

Session incorrectly ended

Ended With 0 Energy After 2 Hour Duration

Session Max Energy Discrepancy

Session with 12h without energy-consumption`;
