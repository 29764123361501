import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const LoginFails = () => {
  return (
    <Stack spacing={2}>
      <Typography>
        <FormattedMessage defaultMessage="Initial step should be to ask them to reset password and try to login again if they have not already. Should this fail, a workplace firewall may be blocking login, as Plug's login requires two domains to be whitelisted, not just one. This can be requested tested by asking the user to attempt to login outside the firewall, such as via mobile phone or at home. If this succeeds, the user should contact their company's IT department and inform of the domain situation." />
      </Typography>

      <Typography>
        <FormattedMessage defaultMessage="Alternatively, if the user has never logged in prior and it's been a month or so since the invitation, the invitation link has likely expired. Try sending a new invitation using the 'Resend invitation' button on the user from the Users page." />
      </Typography>

      <Typography>
        <FormattedMessage defaultMessage="Failing these, contact technical personnel for further assistance in investigating the cause." />
      </Typography>
    </Stack>
  );
};

export const loginFailsString = `Initial step should be to ask them to reset password and try to login again if they have not already. Should this fail, a workplace firewall may be blocking login, as Plug's login requires two domains to be whitelisted, not just one. This can be requested tested by asking the user to attempt to login outside the firewall, such as via mobile phone or at home. If this succeeds, the user should contact their company's IT department and inform of the domain situation.

Alternatively, if the user has never logged in prior and it's been a month or so since the invitation, the invitation link has likely expired. Try sending a new invitation using the 'Resend invitation' button on the user from the Users page.

Failing these, contact technical personnel for further assistance in investigating the cause.`;

export default LoginFails;
