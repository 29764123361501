import { Stack, Typography } from "@mui/material";
import OutOfService1 from "assets/backgrounds/tutorials/ports-and-equipment/out-of-service/1.png";
import OutOfService2 from "assets/backgrounds/tutorials/ports-and-equipment/out-of-service/2.png";
import OutOfService3 from "assets/backgrounds/tutorials/ports-and-equipment/out-of-service/3.png";
import OutOfService4 from "assets/backgrounds/tutorials/ports-and-equipment/out-of-service/4.png";
import OutOfService5 from "assets/backgrounds/tutorials/ports-and-equipment/out-of-service/5.png";
import OutOfService6 from "assets/backgrounds/tutorials/ports-and-equipment/out-of-service/6.png";
import { FormattedMessage } from "react-intl";

import "react-photo-view/dist/react-photo-view.css";

import { useEffect, useRef } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";

const OutOfService = () => {
  const videoRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    // On component mount, clear the src of the iframe to stop the video
    return () => {
      if (videoRef.current) {
        videoRef.current.src = ""; // Clearing the src stops the video
      }
    };
  }, []);

  return (
    <PhotoProvider>
      <Stack spacing={2}>
        <Typography>
          <FormattedMessage defaultMessage="The Out of Service function is available on the Plug Insight Map sidebar and can be set by System or Port owner users. It is particularly helpful for indicating at-a-glance which Shore Power Units and Supply Pits are unavailable, though note that the out of service state will only be set within the context of the Plug Insight map and system." />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="Setting SPUs and/or SPs out of service will:" />
        </Typography>
        <ul>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Update icons on the Plug Insight Map to indicate the out of service state" />
            </Typography>
            <ul>
              <li>
                <Typography>
                  <FormattedMessage defaultMessage="A separate icon will appear 2 weeks ahead of a scheduled out of service period" />
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Display current Out Of Service start and end times (if end time is set), scheduled Out of Service time and out of service messages on SPU/SP equipment pages and popovers" />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Prevent the sending of equipment alarm notifications (email/SMS) from SPUs/SPs that are set out of service" />
            </Typography>
            <ul>
              <li>
                <Typography>
                  <FormattedMessage defaultMessage="Useful for testing equipment without triggering alarm notifications" />
                </Typography>
              </li>
              <li>
                <Typography>
                  <FormattedMessage defaultMessage="Equipment alarms will still be logged and can be seen in Alarm List and Alarm Event History" />
                </Typography>
              </li>
              <li>
                <Typography>
                  <FormattedMessage
                    values={{ i: (...chunks) => <i>{chunks}</i> }}
                    defaultMessage="Other notifications will <i>not</i> be muted"
                  />
                </Typography>
              </li>
            </ul>
          </li>
        </ul>

        <Typography>
          <FormattedMessage defaultMessage="There is a dedicated sidebar page for viewing all equipment out of service, but a quick way to check for out of service periods and comments will be clicking/tapping Supply Pit icons for their popovers." />
        </Typography>
        <PhotoView src={OutOfService1}>
          <img src={OutOfService1} width={500} />
        </PhotoView>
        <PhotoView src={OutOfService2}>
          <img src={OutOfService2} width={500} />
        </PhotoView>
        <PhotoView src={OutOfService3}>
          <img src={OutOfService3} width={500} />
        </PhotoView>

        <Typography variant="h5">
          <FormattedMessage defaultMessage="Setting Supply Pit / SPU Service state:" />
        </Typography>

        <ul>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="The Out of Service function is found on the individual Supply Pit and SPU unit sidebars" />
            </Typography>
            <Stack direction="column" spacing={1} sx={{ my: 1 }}>
              <PhotoView src={OutOfService4}>
                <img src={OutOfService4} width={500} />
              </PhotoView>

              <PhotoView src={OutOfService5}>
                <img src={OutOfService5} width={500} />
              </PhotoView>
            </Stack>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Not setting an end time will leave it Out of Service indefinitely until manually ended" />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="If SPU is set out of service --> all associated SPs set out of service" />
            </Typography>
            <ul>
              <li>
                <Typography>
                  <FormattedMessage defaultMessage="The comment set on SPU when setting out of service will transfer to all associated SPs" />
                </Typography>
              </li>
            </ul>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="If SPU is returned to service --> all associated SPs return to service" />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="If SP is returned to service --> associated out of service SPU will return to service" />
            </Typography>
            <PhotoView src={OutOfService6}>
              <img src={OutOfService6} width={500} />
            </PhotoView>
            <Typography sx={{ mt: 1 }}>
              <FormattedMessage
                values={{ i: (...chunks) => <i>{chunks}</i> }}
                defaultMessage="<i>Left: idle in-service SP | Right: out of service SP</i>"
              />
            </Typography>
          </li>
        </ul>
      </Stack>
    </PhotoProvider>
  );
};

export const outOfServiceString = `The Out of Service function is available on the Plug Insight Map sidebar and can be set by System or Port owner users. It is particularly helpful for indicating at-a-glance which Shore Power Units and Supply Pits are unavailable, though note that the out of service state will only be set within the context of the Plug Insight map and system.

Setting SPUs and/or SPs out of service will:

- Update icons on the Plug Insight Map to indicate the out of service state
    - A separate icon will appear 2 weeks ahead of a scheduled out of service period
- Display current Out Of Service start and end times (if end time is set), scheduled Out of Service time and out of service messages on SPU/SP equipment pages and popovers
- Prevent the sending of equipment alarm notifications (email/SMS) from SPUs/SPs that are set out of service
    - Useful for testing equipment without triggering alarm notifications
    - Equipment alarms will still be logged and can be seen in Alarm List and Alarm Event History
    - Other notifications will *not* be muted

There is a dedicated sidebar page for viewing all equipment out of service, but a quick way to check for out of service periods and comments will be clicking/tapping Supply Pit icons for their popovers.
## **Setting Supply Pit / SPU Service state:**

- The Out of Service function is found on the individual Supply Pit and SPU unit sidebars
- Not setting an end time will leave it Out of Service indefinitely until manually ended
- If SPU is set out of service --> all associated SPs set out of service
    - The comment set on SPU when setting out of service will transfer to all associated SPs
- If SPU is returned to service --> all associated SPs return to service
- If SP is returned to service --> associated out of service SPU will return to service
`;

export default OutOfService;
