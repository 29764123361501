import { Stack, Typography } from "@mui/material";
import Editing1 from "assets/backgrounds/tutorials/vessels/editing/1.png";
import Editing2 from "assets/backgrounds/tutorials/vessels/editing/2.png";
import Editing3 from "assets/backgrounds/tutorials/vessels/editing/3.png";
import Editing4 from "assets/backgrounds/tutorials/vessels/editing/4.png";
import Editing5 from "assets/backgrounds/tutorials/vessels/editing/5.png";
import Editing6 from "assets/backgrounds/tutorials/vessels/editing/6.png";
import Editing7 from "assets/backgrounds/tutorials/vessels/editing/7.png";
import Editing8 from "assets/backgrounds/tutorials/vessels/editing/8.png";
import Editing9 from "assets/backgrounds/tutorials/vessels/editing/9.png";
import { FormattedMessage } from "react-intl";

import "react-photo-view/dist/react-photo-view.css";

import { PhotoProvider, PhotoView } from "react-photo-view";

const EditingVessel = () => {
  return (
    <PhotoProvider>
      <Stack spacing={2}>
        <PhotoView src={Editing1}>
          <img src={Editing1} width={500} />
        </PhotoView>
        <Typography>
          <FormattedMessage defaultMessage="Editing made on vessel details can be split into four categories. <b>Editing Vessel information</b>, <b>Editing Technical information</b>, <b>Editing Tax information</b> and <b>Transferring Ownership</b> (System/Port Owner only)." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="<b>Editing Vessel information</b> follows the same rules as generating a new vessel - with the addition of being able to assign Vessel Users." />
        </Typography>

        <PhotoView src={Editing2}>
          <img src={Editing2} width={500} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="The Vessel User is a Vessel Owner organisation user role that lets the user see and edit the information of their specific assigned vessel, rather than all vessels belonging to the Vessel Owner." />
        </Typography>

        <PhotoView src={Editing3}>
          <img src={Editing3} width={500} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="<b>Editing technical information</b> lets users set vessel technical details: voltage, frequency, number of cables needed / or available and the connection type standard. Connection points are for indicating the rough location for where cables need to go to connect on the vessel." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="<b>Editing tax information</b> lets users set tax details like VAT Exemption state and make Consumption Tax Declarations." />
        </Typography>

        <PhotoView src={Editing4}>
          <img src={Editing4} width={500} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="Use the “Add Country” button under the Tax Information card to set tax info, as it can vary per country." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="For Norway, this will ask the user to set a default VAT Exemption and to make a Consumption Tax Declaration. The default VAT Exemption will apply to all invoices sent for the Vessel in Norway, but the VAT Exemption state can still be adjusted per Port Call if needed." />
        </Typography>

        <PhotoView src={Editing5}>
          <img src={Editing5} width={500} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="To make a declaration, pick whichever applies and sign off on it. The declaration will be logged for the current year and timestamp, and the user that made the declaration will be logged as responsible." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="Tax consumption declarations should ideally be done by the Vessel Owners themselves, so they are logged as responsible." />
        </Typography>

        <PhotoView src={Editing6}>
          <img src={Editing6} width={500} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="System users can hover over the info icons to see the datetime and which user set them. Port Owner and Vessel Owner users will only see the datetime. It’s also possible to edit and change the current selection via each country’s action bar." />
        </Typography>

        <PhotoView src={Editing7}>
          <img src={Editing7} width={500} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="<b>Transferring Ownership</b> comes in two variants. <b>Transfer Ownership</b> and <b>Change Owner</b>." />
        </Typography>

        <PhotoView src={Editing8}>
          <img src={Editing8} width={500} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="<b>Transfer Ownership</b> creates a new vessel version assigned to a new Vessel Owner and resigns the existing version on the old owner. Resigned versions and all associated information remain accessible and editable by the old owner, but not by the new owner." />
        </Typography>

        <PhotoView src={Editing9}>
          <img src={Editing9} width={500} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="<b>Change Owner</b> is used to move a vessel and all of its information from one Vessel Owner organisation to another, granting full access to the new owner and removing it from the old one." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="Autogenerated Vessels will not have a Vessel Owner and this <b>must</b> be assigned using the <b>Change Owner</b> option before invoicing. This will be prompted at the invoicing stage if not done prior." />
        </Typography>
      </Stack>
    </PhotoProvider>
  );
};

export const editingVesselString = `Editing made on vessel details can be split into four categories. Editing Vessel information, Editing Technical information, Editing Tax information and Transferring Ownership (System/Port Owner only).
Editing Vessel information follows the same rules as generating a new vessel - with the addition of being able to assign Vessel Users.
The Vessel User is a Vessel Owner organisation user role that lets the user see and edit the information of their specific assigned vessel, rather than all vessels belonging to the Vessel Owner.
Editing technical information lets users set vessel technical details: voltage, frequency, number of cables needed / or available and the connection type standard. Connection points are for indicating the rough location for where cables need to go to connect on the vessel.

Editing tax information lets users set tax details like VAT Exemption state and make Consumption Tax Declarations.
Use the “Add Country” button under the Tax Information card to set tax info, as it can vary per country.

For Norway, this will ask the user to set a default VAT Exemption and to make a Consumption Tax Declaration. The default VAT Exemption will apply to all invoices sent for the Vessel in Norway, but the VAT Exemption state can still be adjusted per Port Call if needed.

To make a declaration, pick whichever applies and sign off on it. The declaration will be logged for the current year and timestamp, and the user that made the declaration will be logged as responsible.

Tax consumption declarations should ideally be done by the Vessel Owners themselves, so they are logged as responsible.
System users can hover over the info icons to see the datetime and which user set them. Port Owner and Vessel Owner users will only see the datetime. It’s also possible to edit and change the current selection via each country’s action bar.
Transferring Ownership comes in two variants. Transfer Ownership and Change Owner.
Transfer Ownership creates a new vessel version assigned to a new Vessel Owner and resigns the existing version on the old owner. Resigned versions and all associated information remain accessible and editable by the old owner, but not by the new owner.

Change Owner is used to move a vessel and all of its information from one Vessel Owner organisation to another, granting full access to the new owner and removing it from the old one.

Autogenerated Vessels will not have a Vessel Owner and this must be assigned using the Change Owner option before invoicing. This will be prompted at the invoicing stage if not done prior.`;

export default EditingVessel;
