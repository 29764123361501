import { Stack, Typography } from "@mui/material";
import Reset1 from "assets/backgrounds/faq/resetPassword/1.png";
import { FormattedMessage } from "react-intl";
import { PhotoProvider, PhotoView } from "react-photo-view";

const ResetPassword = () => {
  return (
    <Stack spacing={2}>
      <Typography>
        <FormattedMessage defaultMessage="Users can reset their passwords via the login entry point using the ‘Forgot password?’ prompt, or request the change from Plug / a System admin." />
      </Typography>

      <PhotoProvider>
        <PhotoView src={Reset1}>
          <img width={350} src={Reset1} />
        </PhotoView>
      </PhotoProvider>
    </Stack>
  );
};

export const resetPasswordString =
  "Users can reset their passwords via the login entry point using the ‘Forgot password?’ prompt, or request the change from Plug / a System admin.";

export default ResetPassword;
