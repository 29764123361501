import { Stack, Typography } from "@mui/material";
import Email1 from "assets/backgrounds/faq/changeEmail/1.png";
import { FormattedMessage } from "react-intl";
import { PhotoProvider, PhotoView } from "react-photo-view";

const ChangeEmail = () => {
  return (
    <Stack spacing={2}>
      <Typography>
        <FormattedMessage defaultMessage="Users can update their login email if they’re logged in. This should be done with care, as one needs the login password and access to the old email for verification." />
      </Typography>

      <Typography>
        <FormattedMessage defaultMessage="To update, go to the My Profile page found in the righthand sidebar, select the ‘Update Email’ option and input password and the NEW email address." />
      </Typography>

      <PhotoProvider>
        <PhotoView src={Email1}>
          <img width={350} src={Email1} />
        </PhotoView>
      </PhotoProvider>

      <Typography>
        <FormattedMessage defaultMessage="The current login account will be disabled and an email will be sent to the OLD email requesting verification of the change. Login from here is only possible on the NEW email, after verification." />
      </Typography>

      <Typography>
        <FormattedMessage
          values={{ i: (...chunks) => <i>{chunks}</i> }}
          defaultMessage="<i><b>Note that this will not change the password, only the login email.</b></i> Users can continue logging in with their old password, unless they reset it the usual way → via ”Forgot password?” on login page."
        />
      </Typography>

      <Typography>
        <FormattedMessage defaultMessage="Login email can also be changed by a System admin user, while on the specific user’s details page." />
      </Typography>
    </Stack>
  );
};

export const changeEmailString = `Users can update their login email if they’re logged in. This should be done with care, as one needs the login password and access to the old email for verification.

To update, go to the My Profile page found in the righthand sidebar, select the ‘Update Email’ option and input password and the **NEW** email address.

The current login account will be disabled and an email will be sent to the OLD email requesting verification of the change. Login from here is only possible on the NEW email, after verification.

***Note that this will not change the password, only the login email.*** Users can continue logging in with their old password, unless they reset it the usual way → via ”Forgot password?” on login page.

Login email can also be changed by a System admin user, while on the specific user’s details page.

`;

export default ChangeEmail;
