import { useEffect } from "react";
import isEqual from "lodash.isequal";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { Redirect, Switch, useLocation } from "react-router-dom";

import Feedback from "components/Feedback";
import { useUserStore } from "components/stores/UserStore";
import AuthorisationDetails from "modules/AuthorisationDetails";
import ViewAsRedirect from "modules/viewAsRedirect";
import { history } from "utils/history";
import { defaultPlausibleProps, trackPageview } from "utils/plausible";
import { lazy } from "utils/react-lazy-with-reload";

import Providers from "./Providers";

const AdvancedMapPage = lazy(() => import("modules/advancedMap"));
const MapMobileLayout = lazy(
  () => import("modules/advancedMap/mobile/MapMobileLayout"),
);
const MapMobileOutOfServicePage = lazy(
  () => import("modules/advancedMap/mobile/OutOfService"),
);
const MapMobileShorePowerUnitsPage = lazy(
  () => import("modules/advancedMap/mobile/ShorePowerUnits"),
);
const MapMobileSpuDetails = lazy(
  () =>
    import("modules/advancedMap/mobile/ShorePowerUnits/MapMobileSpuDetails"),
);
const MapMobileSupplyPitsPage = lazy(
  () => import("modules/advancedMap/mobile/SupplyPits"),
);
const MapMobileSupplyPitsDetailsPage = lazy(
  () => import("modules/advancedMap/mobile/SupplyPits/Details"),
);
const AlarmCategories = lazy(() => import("modules/alarmCategories"));
const Login = lazy(() => import("modules/auth/Login"));
const PrivacyPolicy = lazy(() => import("modules/auth/PrivacyPolicyPage"));
const PrivacyPolicyAccept = lazy(
  () => import("modules/auth/PrivacyPolicyPage/PrivacyPolicyAccept"),
);
const Auth0VerificationError = lazy(
  () => import("modules/auth0VerificationError"),
);
const Countries = lazy(() => import("modules/countries"));
const CustomersPage = lazy(() => import("modules/customers"));
const CustomerDetails = lazy(() => import("modules/customers/CustomerDetails"));
const DashboardPage = lazy(() => import("modules/dashboard"));
const SmartPortPage = lazy(() => import("modules/smartPort"));
const AssetDetailsPage = lazy(
  () => import("modules/smartPort/AssetDetailsPage"),
);
const InvoicesPage = lazy(() => import("modules/invoices"));
const EditInvoice = lazy(() => import("modules/invoices/EditInvoice"));
const InvoiceDetails = lazy(() => import("modules/invoices/InvoiceDetails"));
const NewInvoice = lazy(() => import("modules/invoices/NewInvoice"));
const InvoiceServiceProviders = lazy(
  () => import("modules/invoiceServiceProvider"),
);
const OrganisationsPage = lazy(() => import("modules/organisations"));
const OrganisationDetails = lazy(() => import("modules/organisations/Details"));
const MyOrganisationPage = lazy(
  () => import("modules/organisations/my-organisation"),
);
const PortOrganisation = lazy(
  () => import("modules/organisations/PortOrganisation"),
);
const PortCallsPage = lazy(() => import("modules/portcalls"));
const PortCallDetailsPage = lazy(() => import("modules/portcalls/details"));
const PortsPage = lazy(() => import("modules/ports"));
const PortDetails = lazy(() => import("modules/ports/PortDetails"));
const PortConfiguration = lazy(
  () => import("modules/ports/PortDetails/PortConfiguration"),
);

const PortFeesPage = lazy(
  () => import("modules/ports/PortDetails/PortFees/PortFeesPage"),
);
const EnergyReports = lazy(() => import("modules/reports"));
const SPSessionDetailsPage = lazy(
  () => import("modules/SPSessions/SPSessionDetails"),
);
const SPUnitsPage = lazy(() => import("modules/SPUnits"));
const SPUnitDetailsPage = lazy(() => import("modules/SPUnits/SPUnitDetails"));
const SupplyPitsPage = lazy(() => import("modules/supplypits"));
const SupplyPitDetails = lazy(
  () => import("modules/supplypits/supplyPitDetails"),
);
const FaqPage = lazy(() => import("modules/faq"));
const FaqSearchResults = lazy(() => import("modules/faq/SearchResults"));
const InvoicingTutorials = lazy(
  () => import("modules/faq/Tutorials/Invoicing"),
);
const NotifiactionAndAlarmsTutorials = lazy(
  () => import("modules/faq/Tutorials/Todo"),
);
const VesselTutorials = lazy(() => import("modules/faq/Tutorials/Vessels"));
const PortsAndEquipment = lazy(
  () => import("modules/faq/Tutorials/PortsAndEquipment"),
);
const SignalTesting = lazy(() => import("modules/testing/SignalTesting"));
const TodoPage = lazy(() => import("modules/todo"));
const UserNotificationsPage = lazy(
  () => import("modules/user/notifications/UserNotificationsPage"),
);
const UserProfilePage = lazy(
  () => import("modules/user/profile/UserProfilePage"),
);
const Users = lazy(() => import("modules/users"));
const UserDetails = lazy(() => import("modules/users/UserDetails"));
const VesselsPage = lazy(() => import("modules/vessels"));
const MyVesselPage = lazy(() => import("modules/vessels/my-vessel"));
const VesselDetailsPage = lazy(() => import("modules/vessels/VesselDetails"));
const VesselUserInitialInformationGathering = lazy(
  () => import("modules/vessels/VesselUserInitialInformationGathering"),
);

const PrivateRoute = lazy(() => import("components/routes/PrivateRoute"));
const AuthRoute = lazy(() => import("components/routes/AuthRoute"));
const AuthPageWrapper = lazy(() => import("components/routes/AuthPageWrapper"));
const UserRoleBasedRouter = lazy(
  () => import("components/routes/UserRoleBasedRouter"),
);

export default function App() {
  return (
    <>
      <Helmet>
        <title>Plug Insight</title>
      </Helmet>
      <Providers history={history}>
        <AppRouter />
        <Feedback />
      </Providers>
    </>
  );
}

const AppRouter = () => {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const user = useUserStore(
    (s) => s.user,
    (oldUser, newUser) => isEqual(oldUser, newUser),
  );

  useEffect(() => {
    if (user) {
      trackPageview(
        { url: `${window.location.origin}${location.pathname}` },
        { props: defaultPlausibleProps },
      );
    }
  }, [user, location]);

  return (
    <Switch>
      <AuthRoute exact component={Login} path="/login" />
      <AuthRoute
        exact
        component={Auth0VerificationError}
        path="/user-not-verified"
      />
      <AuthRoute exact component={PrivacyPolicy} path="/privacy-policy" />
      <AuthRoute
        exact
        component={PrivacyPolicyAccept}
        path="/privacy-policy/accept"
      />

      <PrivateRoute
        exact
        path="/dashboard"
        component={DashboardPage}
        breadcrumbs={[
          {
            title: formatMessage({ id: "HEADER.DASHBOARD" }),
            pathname: "/dashboard",
          },
        ]}
        requiredRole={[
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
        ]}
      />

      <PrivateRoute
        exact
        path="/smart-port-assets"
        component={SmartPortPage}
        breadcrumbs={[
          {
            title: formatMessage({ defaultMessage: "Assets" }),
            pathname: "/smart-port-assets",
          },
        ]}
        requiredRole={["system-admin", "system-standard"]}
      />

      <PrivateRoute
        exact
        path="/smart-port-assets/:id"
        component={AssetDetailsPage}
        breadcrumbs={[
          {
            title: formatMessage({ defaultMessage: "Assets" }),
            pathname: "/smart-port-assets/:id",
          },
        ]}
        requiredRole={["system-admin", "system-standard"]}
      />

      <PrivateRoute exact path="/redirect" component={ViewAsRedirect} />

      <PrivateRoute
        exact
        path="/reports"
        component={EnergyReports}
        breadcrumbs={[
          {
            title: formatMessage({ defaultMessage: "Reports" }),
            pathname: "/reports",
          },
        ]}
      />

      <PrivateRoute
        exact
        path="/supply-pits"
        component={SupplyPitsPage}
        breadcrumbs={[
          {
            title: formatMessage({ defaultMessage: "Supply pits" }),
            pathname: "/supply-pits",
          },
        ]}
        requiredPermissions={["read:supplypits"]}
      />

      <PrivateRoute
        exact
        path="/supply-pits/:supplyPitId"
        component={SupplyPitDetails}
        requiredPermissions={["read:supplypits"]}
      />

      <PrivateRoute
        exact
        path="/shore-power-units"
        component={SPUnitsPage}
        breadcrumbs={[
          {
            title: formatMessage({ defaultMessage: "Shore power units" }),
            pathname: "/shore-power-units",
          },
        ]}
        requiredPermissions={["read:shorepowerunits"]}
      />
      <PrivateRoute
        exact
        path="/shore-power-units/:shorePowerUnitId"
        component={SPUnitDetailsPage}
        requiredPermissions={["read:shorepowerunits"]}
      />
      <PrivateRoute
        exact
        path="/port-calls"
        component={PortCallsPage}
        breadcrumbs={[
          {
            title: formatMessage({ defaultMessage: "Port calls" }),
            pathname: "/port-calls",
          },
        ]}
        requiredPermissions={["read:portcalls"]}
      />
      <PrivateRoute
        exact
        path="/port-calls/:portCallId"
        component={PortCallDetailsPage}
        requiredPermissions={["read:portcalls"]}
      />
      <PrivateRoute
        exact
        path="/initial-vessel-information"
        component={VesselUserInitialInformationGathering}
        layout={AuthPageWrapper}
        requiredRole={["vesselOwner-vesseluser"]}
      />

      <PrivateRoute
        exact
        path="/sessions/:shorePowerSessionId"
        component={SPSessionDetailsPage}
        requiredPermissions={["read:portcalls"]}
      />
      <Redirect
        from="/shore-power-sessions/:shorePowerSessionId"
        to="/sessions/:shorePowerSessionId"
      />
      <PrivateRoute
        exact
        path="/initial-vessel-information"
        component={VesselUserInitialInformationGathering}
        layout={AuthPageWrapper}
        requiredRole={["vesselOwner-vesseluser"]}
      />
      <PrivateRoute
        exact
        path="/vessels"
        component={VesselsPage}
        breadcrumbs={[
          {
            title: formatMessage({ id: "HEADER.VESSELS" }),
            pathname: "/vessels",
          },
        ]}
        requiredPermissions={["read:vessels"]}
      />
      <PrivateRoute
        exact
        path="/vessels/:vesselId"
        component={VesselDetailsPage}
        requiredPermissions={["read:vessels"]}
      />
      <PrivateRoute
        exact
        path="/my-vessel"
        component={MyVesselPage}
        breadcrumbs={[
          {
            title: formatMessage({ id: "HEADER.MY_VESSEL" }),
            pathname: "/my-vessel",
          },
        ]}
        requiredPermissions={["read:vessels"]}
        requiredRole={["vesselOwner-vesseluser"]}
      />
      <PrivateRoute
        exact
        path="/customers"
        component={CustomersPage}
        breadcrumbs={[
          {
            title: formatMessage({ id: "HEADER.CUSTOMERS" }),
            pathname: "/customers",
          },
        ]}
        requiredPermissions={["read:customers"]}
        requiredRole={[
          "portOwner-admin",
          "portOwner-standard",
          "portService-admin",
          "portService-standard",
          "system-admin",
          "system-standard",
          "vesselOwner-admin",
          "vesselOwner-standard",
        ]}
      />
      <PrivateRoute
        exact
        path="/customers/:customerId"
        component={CustomerDetails}
        requiredPermissions={["read:customers"]}
        requiredRole={[
          "portOwner-admin",
          "portOwner-standard",
          "portService-admin",
          "portService-standard",
          "system-admin",
          "system-standard",
          "vesselOwner-admin",
          "vesselOwner-standard",
        ]}
      />
      <PrivateRoute
        exact
        path="/organisations"
        component={OrganisationsPage}
        breadcrumbs={[
          {
            title: formatMessage({ id: "HEADER.ORGANISATIONS" }),
            pathname: "/organisations",
          },
        ]}
        requiredPermissions={["read:organizations"]}
      />
      <PrivateRoute
        exact
        path="/invoice-service-providers"
        component={InvoiceServiceProviders}
        breadcrumbs={[
          {
            title: formatMessage({
              defaultMessage: "Invoice service providers",
            }),
            pathname: "/invoice-service-providers",
          },
        ]}
        requiredPermissions={["read:billing_providers"]}
      />
      <PrivateRoute
        exact
        path="/port/my-organisation"
        component={PortOrganisation}
        breadcrumbs={[
          {
            title: formatMessage({
              defaultMessage: "My organisation",
              description: "Sidebar / My organisation / Menu",
            }),
            pathname: "/port/my-organisation",
          },
        ]}
        requiredPermissions={["read:organizations"]}
      />
      <PrivateRoute
        exact
        path="/my-organisation"
        component={MyOrganisationPage}
        breadcrumbs={[
          {
            title: formatMessage({
              defaultMessage: "My organisation",
              description: "Sidebar / My organisation / Menu",
            }),
            pathname: "/my-organisation",
          },
        ]}
        requiredPermissions={["read:organizations"]}
      />
      <PrivateRoute
        exact
        path="/organisations/:organisationId"
        component={OrganisationDetails}
        requiredPermissions={["read:organizations"]}
      />
      <PrivateRoute
        exact
        path="/users"
        component={Users}
        breadcrumbs={[
          { title: formatMessage({ id: "HEADER.USERS" }), pathname: "/users" },
        ]}
        requiredPermissions={["read:users"]}
      />

      <PrivateRoute
        exact
        path="/users/:userId"
        component={UserDetails}
        requiredPermissions={["read:users"]}
      />

      <PrivateRoute
        exact
        path="/alarm-categories"
        component={AlarmCategories}
        breadcrumbs={[
          {
            title: formatMessage({ id: "HEADER.ALARM_CATEGORIES" }),
            pathname: "/alarm-categories",
          },
        ]}
        requiredPermissions={["read_alarm_categories"]}
      />
      <PrivateRoute
        exact
        path="/invoices"
        component={InvoicesPage}
        breadcrumbs={[
          {
            title: formatMessage({ id: "HEADER.INVOICES" }),
            pathname: "/invoices",
          },
        ]}
        requiredPermissions={["read:invoices"]}
      />
      <PrivateRoute
        exact
        path="/ports"
        component={PortsPage}
        breadcrumbs={[
          { title: formatMessage({ id: "HEADER.PORTS" }), pathname: "/ports" },
        ]}
        requiredPermissions={["read:ports"]}
        requiredRole={[
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "portService-admin",
          "portService-standard",
          "portControl-standard",
          "portControl-admin",
        ]}
      />

      <PrivateRoute
        exact
        path="/ports/:portid"
        component={PortDetails}
        requiredPermissions={["read:ports"]}
      />

      <PrivateRoute
        exact
        path="/countries"
        component={Countries}
        breadcrumbs={[
          {
            title: formatMessage({ defaultMessage: "Countries" }),
            pathname: "/countries",
          },
        ]}
        requiredRole={["system-admin", "system-standard"]}
      />

      <PrivateRoute
        exact
        path="/ports/fees/:portid"
        component={PortFeesPage}
        requiredPermissions={["read:ports"]}
      />

      <PrivateRoute
        exact
        path="/ports/configuration/:portid"
        component={PortConfiguration}
        requiredPermissions={["read:ports"]}
      />

      {/* <PrivateRoute
        exact
        path="/invoices/new"
        component={NewInvoicePage}
        requiredPermissions={["read:invoices", "write:invoices"]}
        requiredRole={[
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "portService-admin",
          "portService-standard",
        ]} // FIXME: BE adding 'read:invoices' for vesselOwner-*, but we can't yet display it
      /> */}

      <PrivateRoute
        exact
        path="/invoices/new"
        component={NewInvoice}
        requiredPermissions={["read:invoices", "write:invoices"]}
        requiredRole={[
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "portService-admin",
          "portService-standard",
        ]} // FIXME: BE adding 'read:invoices' for vesselOwner-*, but we can't yet display it
      />

      <PrivateRoute exact path="/faq" component={FaqPage} />
      <PrivateRoute
        exact
        path="/faq/search-results"
        component={FaqSearchResults}
      />
      <PrivateRoute
        exact
        path="/faq/tutorials/invoicing"
        component={InvoicingTutorials}
      />

      <PrivateRoute
        exact
        path="/faq/tutorials/ports-and-equipment"
        component={PortsAndEquipment}
      />

      <PrivateRoute
        exact
        path="/faq/tutorials/notification-and-alarms"
        component={NotifiactionAndAlarmsTutorials}
      />

      <PrivateRoute
        exact
        path="/faq/tutorials/vessels"
        component={VesselTutorials}
      />

      <PrivateRoute
        exact
        path="/invoices/:invoiceId/edit"
        component={EditInvoice}
        breadcrumbs={[
          {
            title: formatMessage({ id: "HEADER.INVOICES" }),
            pathname: "/invoices",
          },
          {
            title: formatMessage({ id: "BREADCRUMBS.INVOICE.EDIT" }),
            pathname: "/invoices/:invoiceId/edit",
          },
        ]}
        requiredPermissions={["read:invoices", "update:invoices"]}
        requiredRole={[
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "portService-admin",
          "portService-standard",
        ]} // FIXME: BE adding 'read:invoices' for vesselOwner-*, but we can't yet display it
      />

      <PrivateRoute
        exact
        path="/invoices/:invoiceId"
        component={InvoiceDetails}
        breadcrumbs={[
          {
            title: formatMessage({ id: "HEADER.INVOICES" }),
            pathname: "/invoices",
          },
          {
            title: formatMessage({ id: "BREADCRUMBS.INVOICE.DETAILS" }),
            pathname: "/invoices/:invoiceId",
          },
        ]}
        requiredPermissions={["read:invoices"]}
      />

      <PrivateRoute
        exact
        path="/map"
        showBreadcrumbs={false}
        component={AdvancedMapPage}
        requiredRole={[
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "portService-admin",
          "portService-standard",
          "portControl-admin",
          "portControl-standard",
        ]}
      />

      <PrivateRoute
        exact
        path="/map/m/supply-pits"
        showBreadcrumbs={false}
        layout={MapMobileLayout}
        component={MapMobileSupplyPitsPage}
        requiredRole={[
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "portService-admin",
          "portService-standard",
          "portControl-admin",
          "portControl-standard",
        ]}
      />

      <PrivateRoute
        exact
        path="/map/m/shore-power-units/:id"
        showBreadcrumbs={false}
        layout={MapMobileLayout}
        component={MapMobileSpuDetails}
        requiredRole={[
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "portService-admin",
          "portService-standard",
          "portControl-admin",
          "portControl-standard",
        ]}
      />

      <PrivateRoute
        exact
        path="/map/m/supply-pits/:supplyPitId"
        showBreadcrumbs={false}
        layout={MapMobileLayout}
        component={MapMobileSupplyPitsDetailsPage}
        requiredRole={[
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "portService-admin",
          "portService-standard",
          "portControl-admin",
          "portControl-standard",
        ]}
      />

      <PrivateRoute
        exact
        path="/map/m/out-of-service"
        showBreadcrumbs={false}
        layout={MapMobileLayout}
        component={MapMobileOutOfServicePage}
        requiredRole={[
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "portService-admin",
          "portService-standard",
          "portControl-admin",
          "portControl-standard",
        ]}
      />

      <PrivateRoute
        exact
        path="/map/m/shore-power-units"
        showBreadcrumbs={false}
        layout={MapMobileLayout}
        component={MapMobileShorePowerUnitsPage}
        requiredRole={[
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "portService-admin",
          "portService-standard",
          "portControl-admin",
          "portControl-standard",
        ]}
      />

      <Redirect exact from="/map/m" to="/map/m/supply-pits" />

      <PrivateRoute
        exact
        path="/map/supply-pit/:supplyPitId"
        component={AdvancedMapPage}
      />
      <PrivateRoute
        showBreadcrumbs={false}
        exact
        path="/user/profile"
        component={UserProfilePage}
      />
      <PrivateRoute
        showBreadcrumbs={false}
        exact
        path="/user/notifications"
        component={UserNotificationsPage}
        requiredPermissions={["read:notifications"]}
      />

      <PrivateRoute
        showBreadcrumbs={false}
        exact
        path="/signal-testing"
        component={SignalTesting}
        requiredRole={["system-admin"]}
      />

      <PrivateRoute
        showBreadcrumbs={false}
        exact
        path="/roles-and-permissions"
        component={AuthorisationDetails}
        requiredRole={["system-admin"]}
      />

      <PrivateRoute
        exact
        path="/todo"
        component={TodoPage}
        breadcrumbs={[
          {
            title: formatMessage({
              defaultMessage: "Todo",
              description: "Topnav / Breadcrumb",
            }),
            pathname: "/todo",
          },
        ]}
      />

      <PrivateRoute exact path="/" component={UserRoleBasedRouter} />
    </Switch>
  );
};
