import { Alert, Stack, Typography } from "@mui/material";
import Pricing1 from "assets/backgrounds/tutorials/port-pricing/1.png";
import Pricing2 from "assets/backgrounds/tutorials/port-pricing/2.png";
import Pricing3 from "assets/backgrounds/tutorials/port-pricing/3.png";
import Pricing4 from "assets/backgrounds/tutorials/port-pricing/4.png";
import Pricing5 from "assets/backgrounds/tutorials/port-pricing/5.png";
import Pricing6 from "assets/backgrounds/tutorials/port-pricing/6.png";
import Pricing7 from "assets/backgrounds/tutorials/port-pricing/7.png";
import Pricing8 from "assets/backgrounds/tutorials/port-pricing/8.png";
import Pricing9 from "assets/backgrounds/tutorials/port-pricing/9.png";
import Pricing10 from "assets/backgrounds/tutorials/port-pricing/10.png";
import Pricing11 from "assets/backgrounds/tutorials/port-pricing/11.png";
import Pricing12 from "assets/backgrounds/tutorials/port-pricing/12.png";
import Pricing13 from "assets/backgrounds/tutorials/port-pricing/13.png";
import Pricing14 from "assets/backgrounds/tutorials/port-pricing/14.png";
import { FormattedMessage } from "react-intl";

import "react-photo-view/dist/react-photo-view.css";

import { PhotoProvider, PhotoView } from "react-photo-view";

const PortPricing = () => {
  return (
    <PhotoProvider>
      <Stack spacing={2}>
        <Typography>
          <FormattedMessage defaultMessage="In Plug Insight, ports have multiple pricing levels that must be defined to ensure accurate invoicing for the port. This is done on the port’s detail page (select “ports” in the main menu to the left and find the correct port in the port list)." />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="System and Port Owner organization users can set specific usage fees for high-voltage shore power units at each port. They must also define the kWh price at the supply pit level. This can be configured individually for each supply pit or updated in bulk via the Port pricing page." />
        </Typography>
        <Typography variant="h5">
          <FormattedMessage defaultMessage="Setting Currency" />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="Before fees and supply pit price models can be set and invoiced, the port must be given a preferred invoicing method. This is configured in the “Invoice settings” card (see image below) by choosing either “Invoice service provider” or “Email”. The differences between these methods are displayed during the selection process." />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="Next, the user must determine if the port allows VAT exemptions by checking or unchecking the box at the end of the dialog." />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="The currency is automatically set based on the country where the port is located and cannot be changed unless the country is updated." />
        </Typography>
        <PhotoView src={Pricing1}>
          <img src={Pricing1} width={450} />
        </PhotoView>
        <PhotoView src={Pricing2}>
          <img src={Pricing2} width={450} />
        </PhotoView>
        <Alert severity="warning">
          <FormattedMessage defaultMessage="NB! Changing currency will also require users to update the fixed HV fees and supply pit price models." />
        </Alert>
        <Typography variant="h5">
          <FormattedMessage defaultMessage="Setting Fixed High-Voltage fees per port" />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="To configure port pricing for fees, click “Open fee settings” in the “Fixed HV fees” card to access the “Port pricing” page." />
        </Typography>
        <PhotoView src={Pricing3}>
          <img src={Pricing3} width={450} />
        </PhotoView>
        <Typography>
          <FormattedMessage defaultMessage="On the Port pricing page, you can click “historic log” to view past fees or “edit fees” to update the current fees." />
        </Typography>
        <PhotoView src={Pricing4}>
          <img src={Pricing4} width={450} />
        </PhotoView>
        <Typography>
          <FormattedMessage defaultMessage="The historic log provides an overview of the port's fee schedule, including past and future fee dates, sortable by type. Fee changes apply to port calls with a “Start time” after the specified “Effective from” date and time." />
        </Typography>
        <PhotoView src={Pricing5}>
          <img src={Pricing5} width={450} />
        </PhotoView>
        <Typography>
          <FormattedMessage defaultMessage="The “Edit fees” option allows users to set and schedule future fee prices. Once fees are initially configured, port calls on high-voltage supply pits will automatically include the “Ordinary connection fee” as a line item in port call invoice drafts by default." />
        </Typography>
        <PhotoView src={Pricing6}>
          <img src={Pricing6} width={450} />
        </PhotoView>
        <Alert severity="warning">
          <FormattedMessage defaultMessage="NB! In order to set port pricing on fees, the currency type of the port must be set. The current set currency type is indicated at the top of the “Update fees” dialog." />
        </Alert>
        <Typography variant="h5">
          <FormattedMessage defaultMessage="Changing fee types" />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="Fees apply exclusively to port calls on high-voltage supply pits. To adjust the fee type for a port call, navigate to the port call’s detail page and select “Edit fee type” in the “Invoice details” card." />
        </Typography>
        <PhotoView src={Pricing7}>
          <img src={Pricing7} width={450} />
        </PhotoView>
        <Alert severity="warning">
          <FormattedMessage defaultMessage="NB! If the Port call has been saved as an invoice draft the fee type cannot be changed until the draft is removed." />
        </Alert>
        <Typography>
          <FormattedMessage defaultMessage="Selecting and saving a different fee type updates the invoice line item to reflect the new selection." />
        </Typography>
        <PhotoView src={Pricing8}>
          <img src={Pricing8} width={450} />
        </PhotoView>
        <Typography variant="h5">
          <FormattedMessage defaultMessage="Setting kWh price on supply pit" />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="As mentioned above, the kWh price for a supply pit can be set in two ways:" />
        </Typography>
        <ul>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="By navigating to each supply pit’s detail page." />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="By using the “Port pricing” page to update multiple supply pits at once." />
            </Typography>
          </li>
        </ul>
        <Typography variant="h5">
          <FormattedMessage defaultMessage="Update single supply pit kWh price" />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="On the supply pit’s detail page, go to the “Price model” card. Click “Edit price” to update the current kWh price or schedule a new price using the “Effective from” datetime field." />
        </Typography>
        <PhotoView src={Pricing9}>
          <img src={Pricing9} width={450} />
        </PhotoView>
        <PhotoView src={Pricing10}>
          <img src={Pricing10} width={450} />
        </PhotoView>
        <Typography>
          <FormattedMessage defaultMessage="The supply pit’s details page also displays information about any upcoming pricing changes scheduled for the supply pit, as well as a historic log of changes to the port price. Scheduled price changes that have not yet taken effect can be deleted." />
        </Typography>
        <PhotoView src={Pricing11}>
          <img src={Pricing11} width={450} />
        </PhotoView>
        <Typography variant="h5">
          <FormattedMessage defaultMessage="Bulk update supply pit kWh" />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="To update the kWh price for multiple supply pits in the same port at once, go to the “Port pricing” page. Locate the list of all supply pits in the port, then click “Edit bulk price.”" />
        </Typography>
        <PhotoView src={Pricing12}>
          <img src={Pricing12} width={450} />
        </PhotoView>
        <Typography>
          <FormattedMessage defaultMessage="Choose whether you want to update high-voltage or Low-voltage supply pits. Enter the desired price and “Effective from” date for the change, then select the Supply pits you want to update using the check boxes." />
        </Typography>
        <PhotoView src={Pricing13}>
          <img src={Pricing13} width={450} />
        </PhotoView>
        <PhotoView src={Pricing14}>
          <img src={Pricing14} width={450} />
        </PhotoView>
      </Stack>
    </PhotoProvider>
  );
};

export default PortPricing;
