import axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from "axios";

import { history } from "utils/history";

import type { APICall } from "./utils";

const defaultValues = {
  baseURL: `https://${import.meta.env.REACT_APP_API_URL}`,
};

export const axiosClient = axios.create(defaultValues);

// mock service worker - development only
if (import.meta.env.DEV && import.meta.env.MSW_ENABLED === "true") {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires
  const { worker } = require("./browser");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  worker.start();
}

export type RequestOptions = {
  /**
   * **IMPORTANT**: You'll need to map response type manually with `AxiosResponse<T>`
   *
   * If true, the response will be returned as an `AxiosResponse<T>` object instead of just the `T`
   */
  returnAxiosResponse?: boolean;
  ignoreUnauthorizedLogout?: boolean;
};

function request<
  TResponse = unknown,
  TOptions extends RequestOptions = RequestOptions,
  TBodyData = unknown,
>(
  config: AxiosRequestConfig<TBodyData>,
  options?: TOptions,
): TOptions extends { returnAxiosResponse: true }
  ? Promise<AxiosResponse<TResponse>>
  : Promise<TResponse> {
  // Authorization header with Bearer token is set up in PrivateRoute component
  const onSuccess = (response: AxiosResponse<TResponse>) => {
    // as any is needed, otherwise we can't satisfy conditional return type.
    if (options?.returnAxiosResponse) return response as any;

    // as any is needed, otherwise we can't satisfy conditional return type.
    return response.data as any;
  };

  const onError = (error: AxiosError) => {
    if (
      error?.response &&
      error.response.status === 401 &&
      !options?.ignoreUnauthorizedLogout
    ) {
      history.push(`/login`, { unauthorized401: true });
    }

    // DISPLAY API ERROR MESSAGES ON DEV ENVIRONMENT
    if (import.meta.env.DEV) {
      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
        console.error("Headers:", error.response.headers);
      } else {
        // Something else happened while setting up the request
        // triggered the error
        console.error("Error Message:", error.message);
      }
    }

    return Promise.reject(error.response ?? error.message);
  };

  return axiosClient(config).then(onSuccess).catch(onError);
}

export default request;

export type { APICall };
