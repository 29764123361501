import { Link, Stack, Typography } from "@mui/material";
import Invoice1 from "assets/backgrounds/tutorials/summary-of-invoices/1.jpg";
import Invoice2 from "assets/backgrounds/tutorials/summary-of-invoices/2.jpg";
import Invoice3 from "assets/backgrounds/tutorials/summary-of-invoices/3.jpg";
import Invoice4 from "assets/backgrounds/tutorials/summary-of-invoices/4.jpg";
import Invoice5 from "assets/backgrounds/tutorials/summary-of-invoices/5.jpg";
import Invoice6 from "assets/backgrounds/tutorials/summary-of-invoices/6.jpg";
import Invoice7 from "assets/backgrounds/tutorials/summary-of-invoices/7.jpg";
import Invoice8 from "assets/backgrounds/tutorials/summary-of-invoices/8.jpg";
import Invoice9 from "assets/backgrounds/tutorials/summary-of-invoices/9.jpg";
import Invoice10 from "assets/backgrounds/tutorials/summary-of-invoices/10.jpg";
import Invoice11 from "assets/backgrounds/tutorials/summary-of-invoices/11.jpg";
import Invoice12 from "assets/backgrounds/tutorials/summary-of-invoices/12.jpg";
import { FormattedMessage } from "react-intl";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Link as RouterLink } from "react-router-dom";

const SummaryOfInvoicing = () => {
  return (
    <PhotoProvider>
      <Stack spacing={2}>
        <Typography>
          <FormattedMessage
            defaultMessage="Plug Insight collects the power use data of larger vessels using
        shorepower-providing systems in ports. This data is organized in Port
        Calls, collections of one or more sessions of shorepower use by a vessel
        over a period of time, typically with no more than 12 hours between each
        session in the same port."
          />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="‘System’ & ‘Port Owner’ organisation users may review and use Port Call information to initiate an invoicing process by:" />
        </Typography>

        <ul>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Sending it through API integrations directly to invoice service providers who handle further delivery (e.g. ISPs like Agresso, Xledger and Sage)" />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Sending it as a .csv file via email to a chosen email receiver" />
            </Typography>
          </li>
        </ul>

        <Typography>
          <FormattedMessage defaultMessage="They may further:" />
        </Typography>

        <ul>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Edit Port Call details to adjust the invoice relevant data being sent" />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Edit Supply Pit details to adjust kWh pricing" />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Edit Port details to adjust high voltage fee pricing" />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Adjust invoice information details in invoice draft" />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Create invoice draft versions and save them for later review" />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Send credit note information to match any sent invoice information" />
            </Typography>
          </li>
        </ul>

        <Typography>
          <FormattedMessage
            defaultMessage="All sent invoice (or credit) information is archived in a Plug Insight
        database and can be reviewed in the application. ‘Vessel Owner’
        organisation users will also be able to review sent invoice information
        for Port Calls by vessels they own."
          />
        </Typography>

        <Typography variant="h5">
          <FormattedMessage defaultMessage="To send or draft invoice information" />
        </Typography>

        <ol>
          <li>
            <Typography>
              <FormattedMessage
                values={{
                  link: (
                    <Link target="_blank" component={RouterLink} to="/invoices">
                      <FormattedMessage defaultMessage="Invoices page" />
                    </Link>
                  ),
                }}
                defaultMessage="Go to the {link} and the ‘TO BE INVOICED’ tab"
              />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Create an invoice draft by pressing the icon below the ‘Actions’ column" />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Select and/or verify prefilled invoice information" />
            </Typography>
          </li>
          <li>
            <Typography>
              <FormattedMessage defaultMessage="Press “Send” or the “Save as draft” button" />
            </Typography>
          </li>
        </ol>
        <PhotoView src={Invoice1}>
          <img src={Invoice1} width={450} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="There are five tabs on the Invoices page. <b>TO BE INVOICED</b>, <b>DRAFTS</b> and <b>INVOICED</b> are much as they read." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="The NON-BILLABLE tab covers Port Calls that have been edited to have the ‘Not to be invoiced’ or ‘Externally invoiced’ invoice status, or whose session(s) has energy usage of less than 100 kWh total." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="The ‘MISSING #’ tab will only appear if no invoice number is returned within 6 days from an invoice service provider, indicating potential issues with processing that should be investigated." />
        </Typography>

        <PhotoView src={Invoice2}>
          <img src={Invoice2} width={450} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="Port Calls may automatically or manually be set to the <b>Inspection required</b> invoice state. When this occurs, invoice sending is disabled and the corresponding row in TO BE INVOICED will be highlighted in yellow." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="Hovering over the disabled icon will show any comments a user may have added and link to the To Do list, which can also be found in the sidebar." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="For automated error detection, it is recommended to check the To Do list and determine corrective steps, if needed, before re-enabling sending." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="Hovering over the disabled icon will show any comments a user may have added and link to the To Do list, which can also be found in the sidebar." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="For automated error detection, it is recommended to check the To Do list and determine corrective steps, if needed, before re-enabling sending." />
        </Typography>

        <PhotoView src={Invoice3}>
          <img src={Invoice3} width={450} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="To re-enable sending, users must edit the Port Call itself and change the invoice status from <b>Inspection required</b> to <b>To be invoiced</b>." />
        </Typography>

        <Typography variant="h5">
          <FormattedMessage defaultMessage="Selecting or verifying invoice information" />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="The generated invoice draft may be fully prefilled and ready-to-send, or need to be adjusted as every draft depends on details set elsewhere." />
        </Typography>

        <PhotoView src={Invoice4}>
          <img src={Invoice4} width={450} />
        </PhotoView>

        <Typography>
          <FormattedMessage
            values={{
              link: (
                <Link component={RouterLink} to="/invoice-service-providers">
                  <FormattedMessage defaultMessage="Invoice Service Provider page" />
                </Link>
              ),
            }}
            defaultMessage="<b>Invoice Sender</b> autofills, but requires that an invoice service provider is set up on the {link}, that the ISP has an invoice sender w/company name and number, and finally that both ISP and Invoice Sender has been selected within a Port’s Invoice Settings."
          />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="Alternatively it will require that an email recipient is set up via the Port’s Invoice Settings when sending .csv files has been selected there." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="<b>Sender Organisation</b> autofills as the Port Owner organisation currently set as the owner of the Port. Ports can only be set up if assigned to a Port Owner organisation." />
        </Typography>

        <Typography>
          <FormattedMessage
            values={{ i: (...chunks) => <i>{chunks}</i> }}
            defaultMessage="<b>Invoice Recipient</b> requires a <b>Customer</b>. These are generated from customer lists provided by invoice service providers. The dropdown will be prefilled if a <i>“Default Customer”</i> has been assigned for the Vessel."
          />
        </Typography>

        <Typography>
          <FormattedMessage
            values={{ i: (...chunks) => <i>{chunks}</i> }}
            defaultMessage="<b>Business Identifier</b> is often the Customer’s official company # in its country (e.g. a 9-digit number in Norway) or a custom value specific to the ISP (e.g. VIR001). <b>Country</b> references the Customer entity’s country."
          />
        </Typography>

        <Typography>
          <FormattedMessage
            values={{ i: (...chunks) => <i>{chunks}</i> }}
            defaultMessage="<b>Receiver Organisation</b> autofills as the Vessel Owner organisation currently set as the owner of the vessel, whose users will have access to the invoice information in Plug Insight. Note also that Vessels must be assigned to a Vessel Owner before invoice information can be sent."
          />
        </Typography>

        <Typography>
          <FormattedMessage
            values={{ i: (...chunks) => <i>{chunks}</i> }}
            defaultMessage="<b>Vessel</b> details are derived from the vessel. Consumption tax state is only relevant if the country the Port belongs to requires consumption tax declarations."
          />
        </Typography>
        <PhotoView src={Invoice5}>
          <img src={Invoice5} width={450} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="<b>TIME PERIOD</b> is the duration of the Port Call." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="The <b>Invoice reference</b> is an optional text field that accepts any 64-character message and will default to vessel IMO#." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="The reference field may be prefilled from the Port Call by users with access to it. Adjusting the reference within the invoice draft will not update the reference on the Port Call, but will change the message passed along." />
        </Typography>

        <PhotoView src={Invoice6}>
          <img src={Invoice6} width={450} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="<b>VAT Exemption</b> is relevant for some countries and ports, which can be adjusted on the Countries page found in the sidebar. If not relevant, it should not appear. The specific state set determines the VAT applied to the invoice and is sent as additional information. Note that ‘None’ will apply full VAT." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="Exemption will be prefilled if either:" />
        </Typography>

        <ul>
          <li>
            <FormattedMessage defaultMessage="The Vessel has a VAT Exemption default (default by Vessel)" />
          </li>
          <li>
            <FormattedMessage defaultMessage="A user has directly set the Port Call’s VAT Exemption (set manually)" />
          </li>
          <li>
            <FormattedMessage defaultMessage="A Port Call with a previously selected VAT Exemption exists on the Vessel (auto-set feature)" />
          </li>
        </ul>

        <PhotoView src={Invoice7}>
          <img src={Invoice7} width={450} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="Note that if a VAT Exemption default is not set, or a different one is selected, an option appears to make the current selection the Vessel default, and also that changing the VAT Exemption in the invoice draft will immediately update it for the related Port Call." />
        </Typography>

        <PhotoView src={Invoice8}>
          <img src={Invoice8} width={450} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="<b>Invoice date</b> and <b>Invoice due date</b> will be prefilled from today’s date, except on saved drafts. Changing the invoice date will update the due date ahead some 30 days." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="<b>Invoice line items</b> are listed with a description, a quantity and a unit price. There are three types of line items." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="<b>Session</b> line items prefill based on each session of a Port Call. <b>Description</b> autofills as the supply pit location and name, as well as the start and end times of the session. <b>Quantity</b> is the amount of kWh used in that session. <b>Unit Price</b> is the kWh price set on the Supply Pit." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="<b>High Voltage Fee</b> line items are added for Port Calls with sessions from high voltage shore power units. <b>Description</b> refers to the specific fee type. <b>Quantity</b> is 1. <b>Unit Price</b> is taken from the Port ‘Fixed HV fees’, which can be set via the Port Pricing page. Fee type can be manually swapped via the ‘Edit fee type’ option within the invoice draft’s Port Call page." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="<b>Custom</b> line items are custom lines by the user, using the ‘add invoice line’ button." />
        </Typography>

        <ul>
          <li>
            <FormattedMessage defaultMessage="CANCEL quits the user out of the invoice draft without saving it " />
          </li>
          <li>
            <FormattedMessage defaultMessage="RESET resets any values back to what they were (except VAT exemption)" />
          </li>
          <li>
            <FormattedMessage defaultMessage="SAVE AS DRAFT saves and puts it on the DRAFT tab" />
          </li>
          <li>
            <FormattedMessage defaultMessage="SEND sends the invoice information and puts it on the INVOICED tab" />
          </li>
        </ul>

        <Typography>
          <FormattedMessage defaultMessage="Note that once invoice information is sent or saved to DRAFT it will disable editing of the associated Port Call. Discarding a drafted invoice will return it to the TO BE INVOICED tab." />
        </Typography>

        <Typography variant="h5">
          <FormattedMessage defaultMessage="Sending a credit note" />
        </Typography>
        <PhotoView src={Invoice9}>
          <img src={Invoice9} width={450} />
        </PhotoView>

        <PhotoView src={Invoice10}>
          <img src={Invoice10} width={450} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="To send a credit note, go to the INVOICED tab, find the invoice reference that was sent and press the Credit Note button from its Actions column or from within its details page." />
        </Typography>

        <PhotoView src={Invoice11}>
          <img src={Invoice11} width={450} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="Once sent a Credit Note will be created in Insight based on the Invoice reference. The Port call will also have a new entry on the TO BE INVOICED tab and be ready to be adjusted and invoiced again." />
        </Typography>

        <Typography variant="h5">
          <FormattedMessage defaultMessage="Review Invoice & Credit Note details" />
        </Typography>

        <PhotoView src={Invoice12}>
          <img src={Invoice12} width={450} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="To view Invoice reference & Credit Note reference details go to the INVOICED tab and select the ‘View’ buttons under the Actions column or click on the blue ‘Type’ buttons." />
        </Typography>

        <Typography variant="h5">
          <FormattedMessage defaultMessage="Additional Info" />
        </Typography>

        <ul>
          <li>
            <FormattedMessage defaultMessage="Most Port Calls will be auto-created and set to the ‘To be invoiced’ metatag state" />
          </li>
          <li>
            <FormattedMessage defaultMessage="Active Port Calls do not show up on the TO BE INVOICED tab" />
          </li>
          <li>
            <FormattedMessage defaultMessage="Many useful direct links have been added between related pages" />
          </li>
          <li>
            <FormattedMessage defaultMessage="The Vessel’s Default Customer exists to speed things along, but if a Vessel is used by multiple companies, one can remove the default setting fully" />
          </li>
        </ul>
      </Stack>
    </PhotoProvider>
  );
};

export default SummaryOfInvoicing;
