import { Link, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

const AdditionVesselInfoAndTips = () => {
  return (
    <Stack spacing={2}>
      <ul>
        <li>
          <Typography>
            <FormattedMessage defaultMessage="GUI input sometimes leads to butterfinger vessel names and incorrectly input IMO#s, so the ‘To Do’ page updates with automated notifications on potential detected discrepancies, among other things" />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage defaultMessage="Invoice information cannot be sent without the Vessel being assigned to a Vessel Owner" />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage defaultMessage="Linking customers to Vessel Owners can be done both via the Organisation and the Customer detail pages" />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage defaultMessage="Vessel Users will on login be prompted to set technical information, tax consumption declaration (if required) and vessel contact details, if these are not set" />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage defaultMessage="Vessel Users and vessel email contact will receive ‘Ended Port Call’ summary emails" />
          </Typography>
          <ul>
            <li>
              <Typography>
                <FormattedMessage defaultMessage="Additional summary email receivers can be added while on the Vessel Owner organisation’s detail page" />
              </Typography>
            </li>
          </ul>
        </li>
        <li>
          <Typography>
            <FormattedMessage defaultMessage="A vessel will only be able to have one active vessel version at a time" />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage defaultMessage="A VAT Exemption change log is available on the Countries config page (System users)" />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage
              values={{
                link: (
                  <Link
                    target="_blank"
                    component={RouterLink}
                    to="/vessels#consumption-tax"
                  >
                    <FormattedMessage defaultMessage="Consumption tax tab" />
                  </Link>
                ),
              }}
              defaultMessage="Users can go to the {link} available on the main Vessels page to set consumption tax declarations in bulk instead of per vessel"
            />
          </Typography>
        </li>
        <li>
          <Typography>
            <FormattedMessage defaultMessage="The Norwegian consumption tax declaration must be renewed every year" />
          </Typography>
        </li>
      </ul>
    </Stack>
  );
};

export const additionalVesselInfoString = `GUI input sometimes leads to butterfinger vessel names and incorrectly input IMO#s, so the ‘To Do’ page updates with automated notifications on potential detected discrepancies, among other things
Invoice information cannot be sent without the Vessel being assigned to a Vessel Owner
Linking customers to Vessel Owners can be done both via the Organisation and the Customer detail pages
Vessel Users will on login be prompted to set technical information, tax consumption declaration (if required) and vessel contact details, if these are not set
Vessel Users and vessel email contact will receive ‘Ended Port Call’ summary emails
Additional summary email receivers can be added while on the Vessel Owner organisation’s detail page
A vessel will only be able to have one active vessel version at a time
A VAT Exemption change log is available on the Countries config page (System users)
Users can go to the Consumption Tax tab available on the main Vessels page to set consumption tax declarations in bulk instead of per vessel
The Norwegian consumption tax declaration must be renewed every year
`;

export default AdditionVesselInfoAndTips;
