import { Stack, Typography } from "@mui/material";
import Creating1 from "assets/backgrounds/tutorials/vessels/creating/1.png";
import Creating2 from "assets/backgrounds/tutorials/vessels/creating/2.png";
import { FormattedMessage } from "react-intl";

import "react-photo-view/dist/react-photo-view.css";

import { PhotoProvider, PhotoView } from "react-photo-view";

const CreatingVessels = () => {
  return (
    <PhotoProvider>
      <Stack spacing={2}>
        <Typography>
          <FormattedMessage defaultMessage="Vessel creation is largely automatic, occurring the first time a new IMO# and vessel name is registered as input into the graphical user interface of the various Supply Pits and received by Plug Insight." />
        </Typography>
        <PhotoView src={Creating1}>
          <img src={Creating1} width={400} />
        </PhotoView>
        <Typography>
          <FormattedMessage
            defaultMessage="System, Port Owner and Vessel Owner organisation Admins may manually create Vessels from the Vessels page “New Vessels” button. First input is to set the unique 7-digit IMO# of the vessel.
"
          />
        </Typography>
        <PhotoView src={Creating2}>
          <img src={Creating2} width={400} />
        </PhotoView>

        <Typography>
          <FormattedMessage defaultMessage="When manually adding new vessels, only setting the <b>IMO#</b>, <b>Owner</b> and <b>Vessel name</b> are mandatory." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="<b>Owner</b> refers to Vessel Owner organisations. If the right Vessel Owner organisation does not exist in Plug Insight, a System or Port Owner user must create it and invite its first user(s)." />
        </Typography>

        <Typography>
          <FormattedMessage
            values={{ i: (...chunks) => <i>{chunks}</i> }}
            defaultMessage="<b>Default Customer</b> sets the default customer recipient for invoices made using the Vessel’s Port Calls. <i>Only customers which have been linked to the Vessel Owner may be set as default customers.</i>"
          />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="<b>Vessel name</b> is ideally the exact same one as the IMO# is currently registered under." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="<b>Vessel type</b> exists to give context to the type of vessel." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="<b>Vessel phone number</b> and <b>email</b> fields serve as contact information fields - useful in case of following up on technical issues or where the main point of vessel contact is different from the Vessel User." />
        </Typography>
      </Stack>
    </PhotoProvider>
  );
};

export const createVesselsString = `Vessel creation is largely automatic, occurring the first time a new IMO# and vessel name is registered as input into the graphical user interface of the various Supply Pits and received by Plug Insight.
System, Port Owner and Vessel Owner organisation Admins may manually create Vessels from the Vessels page “New Vessels” button. First input is to set the unique 7-digit IMO# of the vessel.
When manually adding new vessels, only setting the IMO#, Owner and Vessel name are mandatory.

Owner refers to Vessel Owner organisations. If the right Vessel Owner organisation does not exist in Plug Insight, a System or Port Owner user must create it and invite its first user(s).

Default Customer sets the default customer recipient for invoices made using the Vessel’s Port Calls. Only customers which have been linked to the Vessel Owner may be set as default customers.

Vessel name is ideally the exact same one as the IMO# is currently registered under.

Vessel type exists to give context to the type of vessel.

Vessel phone number and email fields serve as contact information fields - useful in case of following up on technical issues or where the main point of vessel contact is different from the Vessel User.
`;

export default CreatingVessels;
