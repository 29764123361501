import { Suspense, useEffect, type FC } from "react";
import type * as H from "history";
import { CookiesProvider } from "react-cookie";
import { useIntl } from "react-intl";
import { Router } from "react-router-dom";
import { setLocale } from "yup";

import { SubsidiaryOrganisationProvider } from "components/layouts/DefaultLayout/Context/SubsidiaryOrganisationFilterContext";
import {
  LayoutSplashScreen,
  MetronicSplashScreenProvider,
} from "components/MetronicSplashScreen";
import CookieConsetWrapper from "components/providers/CookiesConsent/CookieConsetWrapper";
import FeatureFlagsProvider from "components/providers/FeatureFlagsProvider";
import I18nContextProvider from "components/providers/I18nContextProvider";
import I18nProvider from "components/providers/I18nProvider";
import MUIProvider from "components/providers/MUIProvider";
import { FaqContextProvider } from "modules/faq/Context/FaqContext";
import { SmartContextProvider } from "modules/smartPort/SmartPortContext";

import GlobalErrorBoundary from "./components/ErrorBoundary/GlobalErrorBoundary";
import Auth0Provider from "./components/providers/Auth0Provider";
import ReactQueryProvider from "./components/providers/ReactQueryProvider";
import SnackbarProvider from "./components/providers/SnackbarProvider";

const Providers: FC<{ history: H.History }> = ({ children, history }) => {
  return (
    <>
      <FeatureFlagsProvider>
        <I18nContextProvider>
          <I18nProvider>
            <MUIProvider>
              <GlobalErrorBoundary>
                <MetronicSplashScreenProvider>
                  <CookiesProvider>
                    <Suspense fallback={<LayoutSplashScreen />}>
                      <Router history={history}>
                        <Auth0Provider>
                          <SubsidiaryOrganisationProvider>
                            <SmartContextProvider>
                              <FaqContextProvider>
                                <CookieConsetWrapper>
                                  <SnackbarProvider>
                                    <ReactQueryProvider>
                                      <YupLocaleInitilizer>
                                        {children}
                                      </YupLocaleInitilizer>
                                    </ReactQueryProvider>
                                  </SnackbarProvider>
                                </CookieConsetWrapper>
                              </FaqContextProvider>
                            </SmartContextProvider>
                          </SubsidiaryOrganisationProvider>
                        </Auth0Provider>
                      </Router>
                    </Suspense>
                  </CookiesProvider>
                </MetronicSplashScreenProvider>
              </GlobalErrorBoundary>
            </MUIProvider>
          </I18nProvider>
        </I18nContextProvider>
      </FeatureFlagsProvider>
    </>
  );
};

export default Providers;

const YupLocaleInitilizer: FC = ({ children }) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    setLocale({
      mixed: {
        required: () => formatMessage({ id: "FORMS.ERROR.FIELD_REQUIRED" }),
      },
      number: {
        min: ({ min, label }) =>
          formatMessage({ id: "FORMS.ERROR.FIELD_MIN" }, { label, min }),
        max: ({ max, label }) =>
          formatMessage({ id: "FORMS.ERROR.FIELD_MAX" }, { label, max }),
      },
    });
  }, [formatMessage]);

  return <>{children}</>;
};
