import { Stack, Typography } from "@mui/material";
import ReasigningImage from "assets/backgrounds/tutorials/vessels/resigning/1.png";
import { FormattedMessage } from "react-intl";

import "react-photo-view/dist/react-photo-view.css";

import { PhotoProvider, PhotoView } from "react-photo-view";

const ResigningVessel = () => {
  return (
    <PhotoProvider>
      <Stack spacing={2}>
        <PhotoView src={ReasigningImage}>
          <img src={ReasigningImage} width={500} />
        </PhotoView>
        <Typography>
          <FormattedMessage defaultMessage="System users may <b>Resign</b> or <b>Decommission</b> vessels manually. Resigning also occurs automatically as part of Transfer Ownership." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="Resigning a vessel will give it the ‘Resigned’ state and disable several edit options. If a resigned vessel’s IMO# is then input in Supply Pit GUI or added as part of a manual “New vessel” creation, a new active ‘vessel version’ will be generated for that vessel on the same IMO#." />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="Decommissioning is used as a resign alternative for vessels that are no longer in use (sunk, scrapped, etc.), and is based on the vessel’s unique IMO#. Using a decommissioned vessel’s IMO# to create a new vessel version will not be possible." />
        </Typography>
      </Stack>
    </PhotoProvider>
  );
};

export const resigningVesselString = `System users may Resign or Decommission vessels manually. Resigning also occurs automatically as part of Transfer Ownership.

Resigning a vessel will give it the ‘Resigned’ state and disable several edit options. If a resigned vessel’s IMO# is then input in Supply Pit GUI or added as part of a manual “New vessel” creation, a new active ‘vessel version’ will be generated for that vessel on the same IMO#.

Decommissioning is used as a resign alternative for vessels that are no longer in use (sunk, scrapped, etc.), and is based on the vessel’s unique IMO#. Using a decommissioned vessel’s IMO# to create a new vessel version will not be possible.
`;

export default ResigningVessel;
