// Invoicing Tutorial content
export const invoicingTutorialString = `Summary of Invoicing via Plug Insight
Plug Insight collects the power use data of larger vessels using shorepower-providing systems in ports. This data is organized in Port Calls, collections of one or more sessions of shorepower use by a vessel over a period of time, typically with no more than 12 hours between each session in the same port.
‘System’ & ‘Port Owner’ organisation users may review and use Port Call information to initiate an invoicing process by:
Sending it through API integrations directly to invoice service providers who handle further delivery (e.g. ISPs like Agresso, Xledger and Sage)
Sending it as a .csv file via email to a chosen email receiver
They may further:
Edit Port Call details to adjust the invoice relevant data being sent
Edit Supply Pit details to adjust kWh pricing
Edit Port details to adjust high voltage fee pricing
Adjust invoice information details in invoice draft
Create invoice draft versions and save them for later review
Send credit note information to match any sent invoice information
All sent invoice (or credit) information is archived in a Plug Insight database and can be reviewed in the application. ‘Vessel Owner’ organisation users will also be able to review sent invoice information for Port Calls by vessels they own.
To send or draft invoice information
Go to the Invoices page and the ‘TO BE INVOICED’ tab
Create an invoice draft by pressing the icon below the ‘Actions’ column
Select and/or verify prefilled invoice information
Press “Send” or the “Save as draft” button

There are five tabs on the Invoices page. TO BE INVOICED, DRAFTS and INVOICED are much as they read.
The NON-BILLABLE tab covers Port Calls that have been edited to have the ‘Not to be invoiced’ or ‘Externally invoiced’ invoice status, or whose session(s) has energy usage of less than 100 kWh total.
The ‘MISSING #’ tab will only appear if no invoice number is returned within 6 days from an invoice service provider, indicating potential issues with processing that should be investigated.

Port Calls may automatically or manually be set to the Inspection required invoice state. When this occurs, invoice sending is disabled and the corresponding row in TO BE INVOICED will be highlighted in yellow.
Hovering over the disabled icon will show any comments a user may have added and link to the To Do list, which can also be found in the sidebar.
For automated error detection, it is recommended to check the To Do list and determine corrective steps, if needed, before re-enabling sending.

To re-enable sending, users must edit the Port Call itself and change the invoice status from Inspection required to To be invoiced.
Selecting or verifying invoice information
The generated invoice draft may be fully prefilled and ready-to-send, or need to be adjusted as every draft depends on details set elsewhere.

Invoice Sender autofills, but requires that an invoice service provider is set up on the Invoice Service Provider page, that the ISP has an invoice sender w/company name and number, and finally that both ISP and Invoice Sender has been selected within a Port’s Invoice Settings. 
Alternatively it will require that an email recipient is set up via the Port’s Invoice Settings when sending .csv files has been selected there.

Sender Organisation autofills as the Port Owner organisation currently set as the owner of the Port. Ports can only be set up if assigned to a Port Owner organisation.
Invoice Recipient requires a Customer. These are generated from customer lists provided by invoice service providers. The dropdown will be prefilled if a “Default Customer” has been assigned for the Vessel.
Business Identifier is often the Customer’s official company # in its country (e.g. a 9-digit number in Norway) or a custom value specific to the ISP (e.g. VIR001). Country references the Customer entity’s country.
Receiver Organisation autofills as the Vessel Owner organisation currently set as the owner of the vessel, whose users will have access to the invoice information in Plug Insight. Note also that Vessels must be assigned to a Vessel Owner before invoice information can be sent.
Vessel details are derived from the vessel. Consumption tax state is only relevant if the country the Port belongs to requires consumption tax declarations.

TIME PERIOD is the duration of the Port Call.
The Invoice reference is an optional text field that accepts any 64-character message and will default to vessel IMO#.
The reference field may be prefilled from the Port Call by users with access to it. Adjusting the reference within the invoice draft will not update the reference on the Port Call, but will change the message passed along.
VAT Exemption is relevant for some countries and ports, which can be adjusted on the Countries page found in the sidebar. If not relevant, it should not appear. The specific state set determines the VAT applied to the invoice and is sent as additional information. Note that ‘None’ will apply full VAT.
Exemption will be prefilled if either:
The Vessel has a VAT Exemption default (default by Vessel)
A user has directly set the Port Call’s VAT Exemption (set manually)
A Port Call with a previously selected VAT Exemption exists on the Vessel (auto-set feature)
Note that if a VAT Exemption default is not set, or a different one is selected, an option appears to make the current selection the Vessel default, and also that changing the VAT Exemption in the invoice draft will immediately update it for the related Port Call.

Invoice date and Invoice due date will be prefilled from today’s date, except on saved drafts. Changing the invoice date will update the due date ahead some 30 days.
Invoice line items are listed with a description, a quantity and a unit price. There are three types of line items.
Session line items prefill based on each session of a Port Call. Description autofills as the supply pit location and name, as well as the start and end times of the session. Quantity is the amount of kWh used in that session. Unit Price is the kWh price set on the Supply Pit.
High Voltage Fee line items are added for Port Calls with sessions from high voltage shore power units. Description refers to the specific fee type. Quantity is 1. Unit Price is taken from the Port ‘Fixed HV fees’, which can be set via the Port Pricing page. Fee type can be manually swapped via the ‘Edit fee type’ option within the invoice draft’s Port Call page.
Custom line items are custom lines by the user, using the ‘add invoice line’ button.

CANCEL quits the user out of the invoice draft without saving it 
RESET resets any values back to what they were (except VAT exemption)
SAVE AS DRAFT saves and puts it on the DRAFT tab
SEND sends the invoice information and puts it on the INVOICED tab
Note that once invoice information is sent or saved to DRAFT it will disable editing of the associated Port Call. Discarding a drafted invoice will return it to the TO BE INVOICED tab.
Sending a credit note

To send a credit note, go to the INVOICED tab, find the invoice reference that was sent and press the Credit Note button from its Actions column or from within its details page.

Once sent a Credit Note will be created in Insight based on the Invoice reference. The Port call will also have a new entry on the TO BE INVOICED tab and be ready to be adjusted and invoiced again.
Review Invoice & Credit Note details

To view Invoice reference & Credit Note reference details go to the INVOICED tab and select the ‘View’ buttons under the Actions column or click on the blue ‘Type’ buttons.
Additional Info
Most Port Calls will be auto-created and set to the ‘To be invoiced’ metatag state
Active Port Calls do not show up on the TO BE INVOICED tab
Many useful direct links have been added between related pages
The Vessel’s Default Customer exists to speed things along, but if a Vessel is used by multiple companies, one can remove the default setting fully
`;
