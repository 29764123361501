import {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react";

import { useDoskolemactionHook } from "api/generated/v2/skolem";
import request from "api/request";
import type { DigitalTwin, DigitalTwinsData } from "api/skolemTypes";

export type SmartContextType = {
  setDigitalTwins: Dispatch<SetStateAction<DigitalTwin[] | null>>;
  digitalTwins: DigitalTwin[] | null;
  sync: () => void;
};

const SmartContext = createContext<SmartContextType | undefined>(undefined);

type SmartPortContextProps = { children: React.ReactNode };

const SmartContextProvider = ({ children }: SmartPortContextProps) => {
  const [digitalTwins, setDigitalTwins] = useState<DigitalTwin[] | null>([]);
  const skolemAction = useDoskolemactionHook();

  const sync = () => {
    skolemAction({
      params: {
        action: "sync", // Example query parameter
      },
    })
      .then(() => {
        request({
          method: "GET",
          url: `/api/v2/skolem/digitaltwins`,
        })
          .then((res) => {
            const response = res as DigitalTwinsData;
            setDigitalTwins(response.data.filter((x) => x.type === null));
          })
          .catch((error) => {
            console.error("Error fetching digital twins:", error);
          });
      })
      .catch((error) => {
        console.error("Error performing skolem action:", error);
      });
  };

  return (
    <SmartContext.Provider
      value={{
        digitalTwins,
        setDigitalTwins,
        sync,
      }}
    >
      {children}
    </SmartContext.Provider>
  );
};

// Create ref and when redirect user from search modal navigate to ref div

function useSmartPortContext() {
  const context = useContext(SmartContext);
  if (context === undefined) {
    throw new Error(
      "useSmartPortContext must be used within a SmartContextProvider",
    );
  }
  return context;
}

export { SmartContext, SmartContextProvider, useSmartPortContext };
