import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type MutableRefObject,
  type SetStateAction,
} from "react";
import { useIntl } from "react-intl";

import { hasRole, useUserStore } from "components/stores/UserStore";

import ChangeEmail, { changeEmailString } from "../FaqComponents/ChangeEmail";
import LoginFails, { loginFailsString } from "../FaqComponents/LoginFails";
import ResetPassword, {
  resetPasswordString,
} from "../FaqComponents/ResetPassword";
import PortPricing from "../Tutorials/Invoicing/PortPricing";
import { portPricingString } from "../Tutorials/Invoicing/PortPricing/portPricingString";
import SummaryOfInvoicing from "../Tutorials/Invoicing/SummaryOfInvoicing";
import { invoicingTutorialString } from "../Tutorials/Invoicing/SummaryOfInvoicing/invoicingTutorialString";
import OutOfService, {
  outOfServiceString,
} from "../Tutorials/PortsAndEquipment/OutOfService";
import ResetCapacity, {
  restCapacityString,
} from "../Tutorials/PortsAndEquipment/RestCapacity";
import PurposeAndDescription from "../Tutorials/Todo/PurposeAndDescription";
import { todoPurposeString } from "../Tutorials/Todo/PurposeAndDescription/purposeTutorialString";
import AdditionVesselInfoAndTips, {
  additionalVesselInfoString,
} from "../Tutorials/Vessels/AdditionalInfo";
import CreatingVessels, {
  createVesselsString,
} from "../Tutorials/Vessels/CreatingVessels";
import EditingVessel, {
  editingVesselString,
} from "../Tutorials/Vessels/EditingVessel";
import ResigningVessel, {
  resigningVesselString,
} from "../Tutorials/Vessels/ResigningVessel";

type tutorialTypeType = "tutorial" | "faq";

export type FaqObjectType = {
  title: string;
  content: JSX.Element;
  contentString: string;
  show?: boolean;
  type: tutorialTypeType;
  category:
    | "invoicing"
    | "notifications"
    | "vessels"
    | "portsAndEquipment"
    | null;
};

export type FaqType = {
  faqQuestions: FaqObjectType[];
  setFaqQuestions: Dispatch<SetStateAction<FaqObjectType[]>>;
  setTutorials: Dispatch<SetStateAction<FaqObjectType[]>>;
  setExpanded: Dispatch<SetStateAction<string | false>>;
  setSearchResults: Dispatch<SetStateAction<FaqObjectType[]>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  tutorials: FaqObjectType[];
  searchDivRef: MutableRefObject<string | null>;
  expanded: string | false;
  searchResults: FaqObjectType[];
  searchTerm: string;
};

const FaqContext = createContext<FaqType | undefined>(undefined);

type FaqProviderProps = { children: React.ReactNode };

const FaqContextProvider = ({ children }: FaqProviderProps) => {
  const [faqQuestions, setFaqQuestions] = useState<FaqObjectType[]>([]);
  const [tutorials, setTutorials] = useState<FaqObjectType[]>([]);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [searchResults, setSearchResults] = useState<FaqObjectType[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [user] = useUserStore((s) => [s.user, s.setUser]);

  const searchDivRef = useRef<string | null>(null);

  const intl = useIntl();

  useEffect(() => {
    const faqQuestions: FaqObjectType[] = [
      {
        title: intl.formatMessage({
          defaultMessage: "How to reset user password?",
        }),
        content: <ResetPassword />,
        type: "faq",
        category: null,
        contentString: resetPasswordString,
        show: true,
      },
      {
        title: intl.formatMessage({
          defaultMessage: "What to do if a user's login fails?",
        }),
        content: <LoginFails />,
        type: "faq",
        category: null,
        contentString: loginFailsString,
        show: true,
      },
      {
        title: intl.formatMessage({
          defaultMessage: "Where do I change my email address?",
        }),
        content: <ChangeEmail />,
        type: "faq",
        category: null,
        contentString: changeEmailString,
        show: true,
      },
    ];

    const tutorials: FaqObjectType[] = [
      // INVOICING ////////////////////////////////
      {
        title: intl.formatMessage({
          defaultMessage: "Invoicing via Plug Insight",
        }),
        content: <SummaryOfInvoicing />,
        contentString: invoicingTutorialString,
        type: "tutorial",
        category: "invoicing",
        show: hasRole([
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
        ]),
      },
      {
        title: intl.formatMessage({
          defaultMessage: "Port pricing",
        }),
        content: <PortPricing />,
        contentString: portPricingString,
        category: "invoicing",
        type: "tutorial",
        show: hasRole([
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
        ]),
      },

      // NOTIFICATIONS ////////////////////////////////
      {
        title: intl.formatMessage({
          defaultMessage: "To do - purpose and description",
        }),
        type: "tutorial",
        category: "notifications",
        content: <PurposeAndDescription />,
        contentString: todoPurposeString,
        show: hasRole([
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
        ]),
      },

      // VESSELS ////////////////////////////////
      {
        title: intl.formatMessage({
          defaultMessage: "Creating vessels",
        }),
        type: "tutorial",
        category: "vessels",
        content: <CreatingVessels />,
        contentString: createVesselsString,
        show: hasRole([
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "vesselOwner-admin",
          "vesselOwner-standard",
        ]),
      },
      {
        title: intl.formatMessage({
          defaultMessage: "Editing vessels",
        }),
        type: "tutorial",
        category: "vessels",
        content: <EditingVessel />,
        contentString: editingVesselString,
        show: hasRole([
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "vesselOwner-admin",
          "vesselOwner-standard",
        ]),
      },
      {
        title: intl.formatMessage({
          defaultMessage: "Resigning & decommissioning vessels",
        }),
        type: "tutorial",
        category: "vessels",
        content: <ResigningVessel />,
        contentString: resigningVesselString,
        show: hasRole([
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "vesselOwner-admin",
          "vesselOwner-standard",
        ]),
      },

      {
        title: intl.formatMessage({
          defaultMessage: "Additional info & tips for vessels",
        }),
        type: "tutorial",
        category: "vessels",
        content: <AdditionVesselInfoAndTips />,
        contentString: additionalVesselInfoString,
        show: hasRole([
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
          "vesselOwner-admin",
          "vesselOwner-standard",
        ]),
      },

      // PORTS & EQUIPMENT ////////////////////////////////

      {
        title: intl.formatMessage({
          defaultMessage: "Out of service",
        }),
        type: "tutorial",
        category: "portsAndEquipment",
        content: <OutOfService />,
        contentString: outOfServiceString,
        show: hasRole([
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
        ]),
      },
      {
        title: intl.formatMessage({
          defaultMessage: "Rest capacity configuration on port",
        }),
        type: "tutorial",
        category: "portsAndEquipment",
        content: <ResetCapacity />,
        contentString: restCapacityString,
        show: hasRole([
          "system-admin",
          "system-standard",
          "portOwner-admin",
          "portOwner-standard",
        ]),
      },
    ];

    setTutorials(tutorials);
    setFaqQuestions(faqQuestions);
  }, [intl, setFaqQuestions, setTutorials, user]);

  return (
    <FaqContext.Provider
      value={{
        faqQuestions,
        setFaqQuestions,
        tutorials,
        setTutorials,
        searchDivRef,
        setExpanded,
        expanded,
        setSearchResults,
        searchResults,
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </FaqContext.Provider>
  );
};

// Create ref and when redirect user from search modal navigate to ref div

function useFaqContext() {
  const context = useContext(FaqContext);
  if (context === undefined) {
    throw new Error("useFaqContext must be used within a FaqContextProvider");
  }
  return context;
}

export { FaqContext, FaqContextProvider, useFaqContext };
