import {
  Alert,
  Box,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Todo1 from "assets/backgrounds/tutorials/todo-purpose/1.png";
import Todo2 from "assets/backgrounds/tutorials/todo-purpose/2.png";
import { FormattedMessage } from "react-intl";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Link as RouterLink } from "react-router-dom";

const PurposeAndDescription = () => {
  return (
    <PhotoProvider>
      <Stack spacing={2}>
        <Typography>
          <FormattedMessage
            values={{
              link: (
                <Link component={RouterLink} to="/todo" target="_blank">
                  <FormattedMessage defaultMessage="To do page" />
                </Link>
              ),
            }}
            defaultMessage="The {link} page contains a list of detected events that require System or Port Owner attention."
          />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="Each ToDo will typically include a link to one or more resources, such as a Shore Power Session or a Vessel (see example below). Certain ToDo triggers a status change to 'Inspection Required' on associated Port Calls, preventing them from being invoiced until status is set to 'To Be Invoiced'." />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="Detected ToDos appear under the “ToDo” tab and be marked as “Done” using the checkboxes to the left. Multiple ToDos can be marked simultaneously." />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="Users can include a custom comment to any ToDo by clicking on the comment icon. The comment will be displayed directly in the table row and may contain up to 300 characters. We recommend including who wrote the comment, such as with a quick “-Ola N”." />
        </Typography>

        <PhotoView src={Todo1}>
          <img src={Todo1} />
        </PhotoView>

        <Typography>
          <FormattedMessage
            values={{ i: (...chunks) => <i>{chunks}</i> }}
            defaultMessage="<i>Screenshot of To Dos</i>"
          />
        </Typography>
        <PhotoView src={Todo2}>
          <img src={Todo2} />
        </PhotoView>
        <Typography>
          <FormattedMessage
            values={{ i: (...chunks) => <i>{chunks}</i> }}
            defaultMessage="<i>Screenshot of “done” To Dos, showcasing a comment</i>"
          />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="ToDos marked ‘Done’ can be deleted or moved back to the “ToDo” tab using the checkboxes" />
        </Typography>
        <Alert severity="warning">
          <FormattedMessage defaultMessage="NOTE: Comments and status changes (marking a ToDo as done) will appear for everyone with access to the To Dos." />
        </Alert>

        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage defaultMessage="Type" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Text displayed" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Explanation" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Side effects" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Vessel with multiple ongoing sessions 
"
                />
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <FormattedMessage
                    values={{ i: (...chunks) => <i>{chunks}</i> }}
                    defaultMessage="The vessel <b><i>VesselName</i></b> has multiple ongoing sessions"
                  />
                </Typography>
              </TableCell>
              <TableCell>
                <Box>
                  <Typography variant="body2">
                    <FormattedMessage
                      values={{ br: <br /> }}
                      defaultMessage="This check happens when: {br} - a session is detected {br}{br}When a new
                  session is detected, a search is made to see if
                  there are other ongoing session on that same vessel. If
                  another ongoing session is detected a ToDo is created."
                    />
                  </Typography>
                </Box>
              </TableCell>
              <TableCell></TableCell>
            </TableBody>
            <TableBody>
              <TableCell>
                <FormattedMessage defaultMessage="IMO not found in port" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  values={{ i: (...chunks) => <i>{chunks}</i> }}
                  defaultMessage="Verify vessel <b><i>VesselName</i></b> on <b><i>Session</i></b>. IMO <b><i>
                IMO</i></b> not found in port."
                />
              </TableCell>
              <TableCell>
                <Box>
                  <Typography variant="body2">
                    <FormattedMessage
                      values={{ br: <br /> }}
                      defaultMessage="This check happens when: {br}
- a session is detected {br}{br}
When a new session is detected, we compare the IMO entered on the equipment with the list of vessels that Marine Traffic bellies are in the port. If this IMO is not found a ToDo is created. "
                    />
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="The PortCall is tagged with “inspectionRequired”" />
              </TableCell>
            </TableBody>
            <TableBody>
              <TableCell>
                <FormattedMessage defaultMessage="Vessel duplicate" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  values={{ i: (...chunks) => <i>{chunks}</i> }}
                  defaultMessage="Newly registered <b><i>VesselName1</i></b> - <b><i>IMO1</i></b> may be a duplicate of existing vessel with similar name <b><i>VesselName2</i></b> - <b><i>IMO2</i></b>"
                />
              </TableCell>
              <TableCell>
                <Box>
                  <Typography variant="body2">
                    <FormattedMessage
                      values={{ br: <br /> }}
                      defaultMessage="This check happens when: {br}
- a vessel is created{br}{br}

This ToDo is appears when we believe that a created vessel, actually is a different vessel. This requires that the vessel is registered in Insight.{br}{br}

The assumption is based on the VesselName and VesselIMO combination, and we try to see if we can find a match on the VessselName that do not match with the inputted IMO.{br}{br}

We run a text-search on all our vessels for the name that was input when the vessel was created, either in Insight or from the equipment. The text-search is “fuzzy” - meaning that it matches not only 100% identical words, but for example “Bergen” and “Beergen” is also a match."
                    />
                  </Typography>
                </Box>
              </TableCell>
              <TableCell></TableCell>
            </TableBody>
            <TableBody>
              <TableCell>
                <FormattedMessage defaultMessage="Vessel name does not match Marine Traffic" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  values={{ i: (...chunks) => <i>{chunks}</i> }}
                  defaultMessage="The vessel name <b><i>VesselName</i></b> does not match the Marine Traffic name on this IMO <b><i>IMO</i></b>"
                />
              </TableCell>
              <TableCell>
                <Box>
                  <Typography variant="body2">
                    <FormattedMessage
                      values={{ br: <br /> }}
                      defaultMessage="This check happens when: {br}
- a session starts{br}
- a vessel is created{br}{br}

We do a comparison on the vessel name between the vessel in Insight, and the vessel in Marine Traffic. If a difference is found a ToDo is created. {br}{br} <b>NB!</b> Check occurs also when a Vessel is manually added, leading to false positive To Do notifications. "
                    />
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="When a new session is created: 
The PortCall is tagged with “inspectionRequired”"
                />
              </TableCell>
            </TableBody>
            <TableBody>
              <TableCell>
                <FormattedMessage defaultMessage="Session incorrectly ended" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  values={{ i: (...chunks) => <i>{chunks}</i> }}
                  defaultMessage="<b><i>Session</i></b> was ended with a timespan of more than 5 minutes between the last and previous message"
                />
              </TableCell>
              <TableCell>
                <Box>
                  <Typography variant="body2">
                    <FormattedMessage
                      values={{ br: <br /> }}
                      defaultMessage="To end a session, we compare the last incoming message from the equipment with the previous one: {br}{br}

If the final message says “OngoingSession”:false, and the previous message before that said “OngoingSession”:true, we know that the that the session is ended, with the final energy being set on the previous message. {br}{br}

If we detect an ended session, but with the previous message being more than five minutes old, we presume that this is not ended correctly. {br}{br}

The reason for notifying is that if the message is too old, there might be more energy consumed than what ends up being invoiced, and it should also warn about missing data."
                    />
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="The PortCall is tagged with “inspectionRequired”" />
              </TableCell>
            </TableBody>
            <TableBody>
              <TableCell>
                <FormattedMessage defaultMessage="Ended With 0 Energy After 2 Hour Duration" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  values={{ i: (...chunks) => <i>{chunks}</i> }}
                  defaultMessage="The <b><i>Session</i></b> ended without energy consumption"
                />
              </TableCell>
              <TableCell>
                <Box>
                  <Typography variant="body2">
                    <FormattedMessage
                      values={{ br: <br /> }}
                      defaultMessage="When session is longer than 2 hours and ends in 0 energy, it may be worth looking at why this occurred and if it is in fact correct."
                    />
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="The PortCall is tagged with “inspectionRequired”" />
              </TableCell>
            </TableBody>
            <TableBody>
              <TableCell>
                <FormattedMessage defaultMessage="Session Max Energy Discrepancy" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  values={{ i: (...chunks) => <i>{chunks}</i> }}
                  defaultMessage="“The <b><i>Session</i></b> has a discrepancy between the max energy and the final value”"
                />
              </TableCell>
              <TableCell>
                <Box>
                  <Typography variant="body2">
                    <FormattedMessage
                      values={{ br: <br /> }}
                      defaultMessage="If the final energy-value that is stored on a session (and used in invoicing) is less than the highest reported energy value on any previous data point."
                    />
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="The PortCall is tagged with “inspectionRequired”" />
              </TableCell>
            </TableBody>
            <TableBody>
              <TableCell>
                <FormattedMessage defaultMessage="Session with 12h without energy-consumption" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  values={{ i: (...chunks) => <i>{chunks}</i> }}
                  defaultMessage="Ongoing <b><i>Session</i></b> had no energy usage for 12h"
                />
              </TableCell>
              <TableCell>
                <Box>
                  <Typography variant="body2">
                    <FormattedMessage
                      values={{ br: <br /> }}
                      defaultMessage="Check once a day - if a session is found to be without consumption we add a todo. Note that this only happens once per session. So if a todo is made and checked “done”, we will not make another."
                    />
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="The PortCall is tagged with “inspectionRequired”" />
              </TableCell>
            </TableBody>
          </Table>
        </Box>
      </Stack>
    </PhotoProvider>
  );
};

export default PurposeAndDescription;
