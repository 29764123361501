import { Grid, Stack, Typography } from "@mui/material";
import RestCapacity3 from "assets/backgrounds/tutorials/ports-and-equipment/rest-capacity/3.png";
import RestCapacity4 from "assets/backgrounds/tutorials/ports-and-equipment/rest-capacity/4.png";
import RestCapacity5 from "assets/backgrounds/tutorials/ports-and-equipment/rest-capacity/5.png";
import RestCapacity6 from "assets/backgrounds/tutorials/ports-and-equipment/rest-capacity/6.png";
import RestCapacity7 from "assets/backgrounds/tutorials/ports-and-equipment/rest-capacity/7.png";
import RestCapacity8 from "assets/backgrounds/tutorials/ports-and-equipment/rest-capacity/8.png";
import { FormattedMessage } from "react-intl";
import { PhotoProvider, PhotoView } from "react-photo-view";

const ResetCapacity = () => {
  return (
    <Stack spacing={2}>
      <PhotoProvider>
        {/* <Typography>
          <FormattedMessage defaultMessage="The previous rest capacity configuration solution was underutilized and did not work as intended. Following a review and dialogue with Plug, we aimed to improve this feature by creating a more visual approach to setting up and defining dependencies among port equipment. " />
        </Typography>
        <PhotoView src={RestCapacity1}>
          <img src={RestCapacity1} width={500} />
        </PhotoView>
        <Typography
          color="gray"
          variant="caption"
          sx={{ position: "relative", top: -10 }}
        >
          <FormattedMessage defaultMessage="We moved the configuration on the individual Shore Power Unit level to a more comprehensive port diagram." />
        </Typography>

        <Typography variant="h6">
          <FormattedMessage defaultMessage="Solution Overview:" />
        </Typography>

        <Typography>
          <FormattedMessage defaultMessage="The outcome is a port configuration feature on the port detail page, allowing users to visually link port equipment (nodes) into a four-level tree structure. In addition to Shore Power Units and Supply Pits that already exist as units in PI, we introduce Grids and Transformers to help group the equipment. The setup of this structure will impact rest capacity calculations across the application, such as the map, and must reflect the real-life configuration of equipment. " />
        </Typography>

        <PhotoView src={RestCapacity2}>
          <img src={RestCapacity2} width={500} />
        </PhotoView>

        <Typography
          color="gray"
          variant="caption"
          sx={{ position: "relative", top: -10 }}
        >
          <FormattedMessage defaultMessage="Examples from the map where rest capacity is displayed" />
        </Typography> */}

        <Typography variant="h5">
          <FormattedMessage defaultMessage="Configuration Overview " />
        </Typography>

        <ol>
          <li>
            <FormattedMessage defaultMessage="Grid is the connection between the power grid and the shore power units. A grid has a name and capacity. A grid can be connected to one or more shore power units. " />
          </li>
          <li>
            <FormattedMessage defaultMessage="Shore Power Unit has an ID, Name, Capacity, and a boolean property unique and can be connected to one or more Transformers. If unique is true, this indicates only one of the connected transformers can be powered at the same time. If one transformer is powered, the other transformers have 0 rest capacity. If unique is false the rest capacity is calculated as normal." />
          </li>
          <li>
            <FormattedMessage defaultMessage="Transformer is a transformer/switch between ShorePowerUnit and SupplyPit(s) in the grid. A Transformer has a capacity and a boolean property named unique which decides whether the supply pits can powered simultaneously. If unique is true, this indicates only one of the connected supply pits can be powered at the same time. If one supply pit is powered, the other supply pits have 0 rest capacity. If unique is false the rest capacity is calculated as normal." />
          </li>
          <li>
            <FormattedMessage defaultMessage="SupplyPit is a supply pit in the system and is connected to a transformer. A supply pit has an ID, Name and Capacity. The rest capacity is calculated from the capacity minus active power if the supply pit is on power. " />
          </li>
        </ol>

        <PhotoView src={RestCapacity3}>
          <img src={RestCapacity3} width={500} />
        </PhotoView>

        <Typography variant="h6">
          <FormattedMessage defaultMessage="Instructions for use: " />
        </Typography>

        <ol>
          <li>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography fontWeight="bold">
                    <FormattedMessage defaultMessage="Getting started:" />
                  </Typography>
                  <Typography>
                    <FormattedMessage defaultMessage="Navigate to the details page of a port. Scroll down and click 'Configure Port' to begin setting up equipment." />
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <PhotoView src={RestCapacity4}>
                    <img src={RestCapacity4} width={300} />
                  </PhotoView>
                </Stack>
              </Grid>
            </Grid>
          </li>

          <li style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography fontWeight="bold">
                    <FormattedMessage defaultMessage="View:" />
                  </Typography>
                  <Typography>
                    <FormattedMessage defaultMessage="Navigate to the details page of a port. Scroll down and click 'Configure Port' to begin setting up equipment." />
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <PhotoView src={RestCapacity5}>
                    <img src={RestCapacity5} width={300} />
                  </PhotoView>
                </Stack>
              </Grid>
            </Grid>
          </li>

          <li style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography fontWeight="bold">
                    <FormattedMessage defaultMessage="Adding Equipment to Canvas: " />
                  </Typography>
                  <Typography>
                    <FormattedMessage defaultMessage="Drag and drop equipment from the sidebar onto the canvas. Click the corresponding '+' buttons to create Grids or Transformers." />
                  </Typography>
                  <ul>
                    <li>
                      <Typography>
                        <FormattedMessage defaultMessage="For Grids and Transformers, provide a name and set a maximum capacity (kW)." />
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <FormattedMessage defaultMessage="Transformers and Shore Power Units can be designated as unique (as described in components)." />
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <FormattedMessage defaultMessage="Floating equipment (not connected to other equipment) will appear in yellow." />
                      </Typography>
                    </li>
                  </ul>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <PhotoView src={RestCapacity6}>
                    <img src={RestCapacity6} width={300} />
                  </PhotoView>
                </Stack>
              </Grid>
            </Grid>
          </li>

          <li style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography fontWeight="bold">
                    <FormattedMessage defaultMessage="Link equipment:" />
                  </Typography>
                  <Typography>
                    <FormattedMessage defaultMessage="Once elements are on the canvas, draw lines between them (drag your cursor from one connection point to another), and arrange the elements them. Lines go Grid → Shore Power Units → Transformers → Supply Pits." />
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <PhotoView src={RestCapacity7}>
                    <img src={RestCapacity7} width={300} />
                  </PhotoView>
                </Stack>
              </Grid>
            </Grid>
          </li>

          <li style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography fontWeight="bold">
                    <FormattedMessage defaultMessage="Edit equipment: " />
                  </Typography>
                  <Typography>
                    <FormattedMessage defaultMessage="Some equipment can be edited by clicking on the three dot icon in the right corner of the node —> edit:" />
                  </Typography>
                  <ul>
                    <li>
                      <Typography>
                        <FormattedMessage defaultMessage="Grid: Name, Max capacity (must be set)" />
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <FormattedMessage defaultMessage="Shore Power Units: unique/ not unique" />
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        <FormattedMessage defaultMessage="Transformers: Name, Max Capacity (must be set), unique / not unique" />
                      </Typography>
                    </li>
                  </ul>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <PhotoView src={RestCapacity8}>
                    <img src={RestCapacity8} width={300} />
                  </PhotoView>
                </Stack>
              </Grid>
            </Grid>
          </li>

          <li style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography fontWeight="bold">
                    <FormattedMessage defaultMessage="Removing equipment:" />
                  </Typography>
                  <Typography>
                    <FormattedMessage defaultMessage="Delete equipment by clicking the three-dot icon on the node's right corner, then select 'delete.' This action removes transformers or grids completely. Shore Power Units and supply pits disappear from the canvas but reappear in the sidebar." />
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </li>

          <li style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography fontWeight="bold">
                    <FormattedMessage defaultMessage="Saving diagram:" />
                  </Typography>
                  <Typography>
                    <FormattedMessage defaultMessage="Save your diagram once completed. Editing can resume later, but saving is only possible when equipment is properly linked: grid, shore power unit, transformer, and supply pit. Ensure no unlinked equipment remains on the canvas. An error message will append." />
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </li>

          <li style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Stack>
                  <Typography fontWeight="bold">
                    <FormattedMessage defaultMessage="Restore Configuration: " />
                  </Typography>
                  <Typography>
                    <FormattedMessage defaultMessage="If a port already has a saved configuration and you want to restore this  one after you started making changes, you can click restore button in top right corner to restore the already saved configuration." />
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </li>
        </ol>
      </PhotoProvider>
    </Stack>
  );
};

export const restCapacityString = `The previous rest capacity configuration solution was underutilized and did not work as intended. Following a review and dialogue with Plug, we aimed to improve this feature by creating a more visual approach to setting up and defining dependencies among port equipment. 
The outcome is a port configuration feature on the port detail page, allowing users to visually link port equipment (nodes) into a four-level tree structure. In addition to Shore Power Units and Supply Pits that already exist as units in PI, we introduce Grids and Transformers to help group the equipment. The setup of this structure will impact rest capacity calculations across the application, such as the map, and must reflect the real-life configuration of equipment. 

 **Grid** is the connection between the power grid and the shore power units. A grid has a **name** and **capacity.** A **grid** can be connected to one or more shore power units. 
 **Shore Power Unit** has an **ID, Name, Capacity**, and a boolean property **unique** and can be connected to one or more **Transformer**s. If **unique** is true, this indicates only one of the connected transformers can be powered at the same time. If one transformer is powered, the other transformers have 0 rest capacity. If **unique** is false the rest capacity is calculated as normal.
 **Transformer** is a transformer/switch between **ShorePowerUnit** and **SupplyPit**(s) in the grid. A **Transformer** has a **capacity** and a boolean property named **unique** which decides whether the supply pits can powered simultaneously. If **unique** is true, this indicates only one of the connected supply pits can be powered at the same time. If one supply pit is powered, the other supply pits have 0 rest capacity. If **unique** is false the rest capacity is calculated as normal.
 **SupplyPit** is a supply pit in the system and is connected to a transformer. A supply pit has an **ID, Name** and **Capacity.** The rest capacity is calculated from the capacity minus active power if the supply pit is on power.
Navigate to the details page of a port. Scroll down and click "Configure Port" to begin setting up equipment.
The port configuration includes a canvas movable via mouse, scroll, or navigation buttons in the corner, and a sidebar listing all Shore Power Units and Supply Pits in the port.
 Drag and drop equipment from the sidebar onto the canvas. Click the corresponding "+" buttons to create Grids or Transformers.
    - For Grids and Transformers, provide a name and set a maximum capacity (kW).
    - Transformers and Shore Power Units can be designated as unique (as described in components).
    - Floating equipment (not connected to other equipment) will appear in yellow.
    Once elements are on the canvas, draw lines between them (drag your cursor from one connection point to another), and arrange the elements them. Lines go Grid → Shore Power Units → Transformers → Supply Pits.
 Some equipment can be edited by clicking on the three dot icon in the right corner of the node —> edit:
    - Grid: Name, Max capacity (must be set)
    - Shore Power Units: unique/ not unique
    - Transformers: Name, Max Capacity (must be set), unique / not unique
    Delete equipment by clicking the three-dot icon on the node's right corner, then select "delete." This action removes transformers or grids completely. Shore Power Units and supply pits disappear from the canvas but reappear in the sidebar.
    Save your diagram once completed. Editing can resume later, but saving is only possible when equipment is properly linked: grid, shore power unit, transformer, and supply pit. Ensure no unlinked equipment remains on the canvas. An error message will appe
    If a port already has a saved configuration and you want to restore this  one after you started making changes, you can click restore button in top right corner to restore the already saved configuration.
    `;

export default ResetCapacity;
