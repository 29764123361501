export const portPricingString = `Summary of Port Pricing
In Plug Insight, ports have multiple pricing levels that must be defined to ensure accurate invoicing for the port. This is done on the port’s detail page (select “ports” in the main menu to the left and find the correct port in the port list).

System and Port Owner organization users can set specific usage fees for high-voltage shore power units at each port. They must also define the kWh price at the supply pit level. This can be configured individually for each supply pit or updated in bulk via the Port pricing page.
Setting Currency
Before fees and supply pit price models can be set and invoiced, the port must be given a preferred invoicing method. This is configured in the “Invoice settings” card (see image below) by choosing either “Invoice service provider” or “Email”. The differences between these methods are displayed during the selection process.
Next, the user must determine if the port allows VAT exemptions by checking or unchecking the box at the end of the dialog. 
The currency is automatically set based on the country where the port is located and cannot be changed unless the country is updated.



NB! Changing currency will also require users to update the fixed HV fees and supply pit price models.


Setting Fixed High-Voltage fees per port
To configure port pricing for fees, click “Open fee settings” in the “Fixed HV fees” card to access the “Port pricing” page. 

On the Port pricing page, you can click “historic log” to view past fees or “edit fees” to update the current fees.


The historic log provides an overview of the port's fee schedule, including past and future fee dates, sortable by type. Fee changes apply to port calls with a “Start time” after the specified “Effective from” date and time. 


The “Edit fees” option allows users to set and schedule future fee prices. Once fees are initially configured, port calls on high-voltage supply pits will automatically include the “Ordinary connection fee” as a line item in port call invoice drafts by default.

NB! In order to set port pricing on fees, the currency type of the port must be set. The current set currency type is indicated at the top of the “Update fees” dialog.

Changing fee types
Fees apply exclusively to port calls on high-voltage supply pits. To adjust the fee type for a port call, navigate to the port call’s detail page and select “Edit fee type” in the “Invoice details” card.


NB! If the Port call has been saved as an invoice draft the fee type cannot be changed until the draft is removed.

Selecting and saving a different fee type updates the invoice line item to reflect the new selection.
 
Setting kWh price on supply pit
As mentioned above, the kWh price for a supply pit can be set in two ways:
By navigating to each supply pit’s detail page.
By using the “Port pricing” page to update multiple supply pits at once.

Update single supply pit kWh price 
On the supply pit’s detail page, go to the “Price model” card. Click “Edit price” to update the current kWh price or schedule a new price using the “Effective from” datetime field.









The supply pit’s details page also displays information about any upcoming pricing changes scheduled for the supply pit, as well as a historic log of changes to the port price. Scheduled price changes that have not yet taken effect can be deleted.



Bulk update supply pit kWh
To update the kWh price for multiple supply pits in the same port at once, go to the “Port pricing” page. Locate the list of all supply pits in the port, then click “Edit bulk price.” 

Choose whether you want to update high-voltage or Low-voltage supply pits. Enter the desired price and “Effective from” date for the change, then select the Supply pits you want to update using the check boxes.


`;
